import React, { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';

import { Typography } from '@/components/Typography';
import { Label } from '@/components/ui/label';
import { imageExtensions, videoExtensions } from '@/constants/common';
import { strings } from '@/locales';

type DragAndDropBoxProps = {
  mediaFile: File | null | string;
  setMediaFile: React.Dispatch<React.SetStateAction<File | null>>;
  mediaError: string;
};

const DragAndDropBox: React.FC<DragAndDropBoxProps> = ({
  mediaFile,
  setMediaFile,
  mediaError,
}) => {
  const staticText = strings.addNews;
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const selectFile = () => {
    fileInputRef.current?.click();
  };

  const onSelectFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files || files.length === 0) return;
    const file = files.item(0);
    if (!file) return;
    setMediaFile(file);
  };

  const deleteMediaFile = () => {
    setMediaFile(null);
    setIsImage(false);
    setIsVideo(false);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
    e.dataTransfer.dropEffect = 'copy';
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    const { files } = e.dataTransfer;
    if (!files || files.length === 0) return;
    const file = files.item(0);
    if (!file) return;
    setMediaFile(file);
  };
  useEffect(() => {
    const fileExtension =
      (typeof mediaFile === 'string' ? mediaFile : mediaFile?.name)
        ?.split('.')
        ?.pop()
        ?.toLowerCase() || '';
    setIsImage(imageExtensions.includes(fileExtension));
    setIsVideo(videoExtensions.includes(fileExtension));
  }, [mediaFile]);

  return (
    <div className='flex flex-col gap-y-2'>
      <Label className='text-base'>{staticText.upload}</Label>
      <div
        className='drag-area h-60 p-0.5'
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className='bg-darkGray relative h-full flex flex-col justify-center items-center'>
          {mediaFile ? (
            <div className='m-7'>
              <div className='bg-dimWhite absolute right-3 top-3 rounded-full size-6 flex items-center justify-center'>
                <span
                  className='text-red-500 cursor-pointer z-50'
                  onClick={deleteMediaFile}
                >
                  <RxCross2 size={15} />
                </span>
              </div>
              {isImage && (
                <img
                  className='h-[238px] w-full object-fill rounded-[14px]'
                  width={600}
                  src={
                    typeof mediaFile === 'string'
                      ? (mediaFile as string)
                      : URL.createObjectURL(mediaFile)
                  }
                />
              )}
              {isVideo && (
                <video
                  className='h-[238px] w-full object-fill rounded-[14px]'
                  src={
                    typeof mediaFile === 'string'
                      ? (mediaFile as string)
                      : URL.createObjectURL(mediaFile)
                  }
                  controls
                />
              )}
            </div>
          ) : (
            <>
              {isDragging ? (
                <span className='select'>{staticText.dragAndDrop}</span>
              ) : (
                <div>
                  {staticText.boxText}{' '}
                  <span
                    className='text-homeWorld'
                    role='button'
                    onClick={selectFile}
                  >
                    {staticText.chooseFile}
                  </span>
                </div>
              )}
            </>
          )}
          <input
            type='file'
            className='file hidden'
            accept='image/jpeg, image/png, image/gif, video/mp4'
            ref={fileInputRef}
            onChange={onSelectFileChange}
          />
        </div>
      </div>
      {mediaError ? (
        <Typography className='text-red-500'>{mediaError}</Typography>
      ) : null}
    </div>
  );
};

export default DragAndDropBox;
