import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { getPaymentHistoryColumns } from '@/components/FilterTable/Columns';
import FilterTable from '@/components/FilterTable/index';
import Loader from '@/components/Loader';
import withProtectedRoute from '@/components/ProtectedRoute';
import { Typography } from '@/components/Typography';
import { COMMON_ERROR } from '@/constants/feedback';
import useDashboardContext from '@/hooks/useDashboard';
import { strings } from '@/locales';
import { getUserDetail } from '@/services/users';

import LinkedCard from './LinkedCard';
import PersonalInfoCard from './PersonallInfoCard';
import UserDetailTitleSection from './TitleSection';
import UserDetailSection from './UserDetailSection';

const UserDetail: React.FC = () => {
  const staticText = strings.userDetailScreen;
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setUser, user } = useDashboardContext();
  const { userId } = useParams<{ userId: string }>();

  const columns = useMemo(() => getPaymentHistoryColumns(), []);
  useEffect(() => {
    setIsLoading(true);
    const fetchlUserData = async () => {
      try {
        const response = await getUserDetail(userId as string);
        setUser(response?.data);
        setIsLoading(false);
      } catch (error) {
        toast.error(COMMON_ERROR);
      }
    };
    fetchlUserData();
  }, []);

  return (
    <div className='my-6'>
      <UserDetailTitleSection />
      {!isLoading ? (
        <>
          <PersonalInfoCard isEdit={isEdit} setIsEdit={setIsEdit} />
          <div className='mt-6 w-full border border-borderWhite rounded-xl p-5'>
            <Typography className='font-semibold text-lg md:text-[22px] mb-3'>
              {staticText.cardDetails}
            </Typography>
            <UserDetailSection
              fields={[
                {
                  label: staticText.totalAmount,
                  value: user?.subscription?.amount
                    ? `$${user?.subscription?.amount}`
                    : 'N/A',
                },
                {
                  label: staticText.nextPaymentDate,
                  value:
                    dayjs(user?.subscription?.nextPaymentDate).format(
                      'YYYY-MM-DD',
                    ) || 'N/A',
                },
                {
                  label: staticText.paymentMethod,
                  value: user?.subscription?.paymentMethod || 'N/A',
                },
                {
                  label: staticText.expiresAt,
                  value:
                    user?.cardDetails?.expMonth && user?.cardDetails?.expYear
                      ? `${user.cardDetails.expMonth}/${user.cardDetails.expYear}`
                      : 'N/A',
                },
              ]}
            />
          </div>
          <LinkedCard user={user} />
          <div className='mt-6 w-full border border-borderWhite rounded-xl p-5'>
            <Typography variant='subheading' className='text-lg md:text-2xl'>
              {strings.userDetailScreen.paymentHistory}
            </Typography>
            <FilterTable data={user?.paymentHistory || []} columns={columns} />
          </div>
        </>
      ) : (
        <div className='h-96 flex justify-center items-center w-full'>
          <Loader loading={isLoading} />
        </div>
      )}
    </div>
  );
};

export default withProtectedRoute(UserDetail);
