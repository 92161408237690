import { UseFormHandleSubmit } from 'react-hook-form';
import { FiEdit } from 'react-icons/fi';

import { userDataType } from '@/@types';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { useUserDetail } from '@/hooks/useUserDetail';
import { strings } from '@/locales';

type PersonalInfoCardType = {
  isEdit: boolean;
  setIsEdit: (value: boolean) => void;
  handleSubmit: UseFormHandleSubmit<userDataType, undefined>;
};

const PersonalInfoCard: React.FC<PersonalInfoCardType> = ({
  isEdit,
  setIsEdit,
  handleSubmit,
}) => {
  const staticText = strings.userDetailScreen;
  const { updateProfileHandler } = useUserDetail(isEdit, setIsEdit);

  return (
    <div className='flex justify-between'>
      <Typography className='font-semibold text-lg md:text-[22px] mb-3'>
        {staticText.personalInformation}
      </Typography>
      <div className='flex gap-2'>
        {isEdit ? (
          <>
            <Button
              variant={'primary'}
              onClick={() => setIsEdit(!isEdit)}
              className='rounded-full bg-blueFantastic p-3 sm:p-5 font-medium text-base'
            >
              {strings.dashboard.cacnel}
            </Button>
            <Button
              variant={'primary'}
              onClick={handleSubmit(updateProfileHandler)}
              className='rounded-full bg-blueFantastic p-3 sm:p-5 font-medium text-base'
            >
              <FiEdit className='mr-2 sm:mr-4' size={19} />
              {strings.dashboard.update}
            </Button>
          </>
        ) : null}
        {!isEdit ? (
          <Button
            variant={'primary'}
            onClick={() => setIsEdit(!isEdit)}
            className='rounded-full bg-blueFantastic p-3 sm:p-5 font-medium text-base'
          >
            <FiEdit className='mr-2 sm:mr-4' size={19} />
            {strings.dashboard.edit}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default PersonalInfoCard;
