import React from 'react';
import { GetCity } from 'react-country-state-city';
import { FieldErrors } from 'react-hook-form';

import { strings } from '@/locales';

import AddressSelect, { CityType, StatesType } from './AddressSelect';

type StateFieldsProps = {
  errors: FieldErrors;
  value: string;
  onChange: () => void;
  countryId: number;
  stateList: StatesType[];
  setCityList: (cities: CityType[]) => void;
  setStateId: (stateId: number) => void;
  stateId: number;
  isDisabled?: boolean;
};

const StateSelect: React.FC<StateFieldsProps> = ({
  errors,
  value,
  onChange,
  countryId,
  stateList,
  setCityList,
  setStateId,
  stateId,
  isDisabled = false,
}) => {
  const handleStateChange = (fieldOnChange: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedState = stateList[parseInt(e.target.value)];
      if (selectedState) {
        setStateId(selectedState.id);
        GetCity(countryId, selectedState.id).then((cities: CityType[]) => {
          setCityList(cities);
        });
      }
      fieldOnChange(e.target.value);
    };
  };
  return (
    <AddressSelect
      isDisabled={isDisabled}
      labelCalssName='text-base'
      onChange={handleStateChange(onChange)}
      errors={errors}
      dataList={stateList}
      value={value || stateId}
      title={strings.userDetailScreen.state}
      placeholder={strings.userDetailScreen.select}
    />
  );
};

export default StateSelect;
