import React from 'react';

import { strings } from '@/locales';

const NotFound = () => (
  <div>
    <div className='absolute inset-0 w-full'>
      <h1 className='text-center flex h-full justify-center items-center'>
        {strings.pageNotFoundScreen.pageNotFound}
      </h1>
    </div>
  </div>
);

export default NotFound;
