import toast from 'react-hot-toast';

import {
  forgotPasswordRequest,
  forgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  OtpRequest,
  OtpResponse,
  ResendOtpRequest,
  resetPasswordRequest,
  resetPasswordResponse,
  signInASUserResponse,
} from '@/@types/authTypes';
import { USER_TYPE } from '@/constants/common';
import { strings } from '@/locales';
import { IEmailField } from '@/pages/SignInAsUser';

import { API } from 'src/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from 'src/constants/feedback';
import { REST_SUB_URL } from 'src/constants/url';
import {
  setRememberMeToLS,
  setRememberMeToSS,
  setTokenToLS,
  setTokenToSS,
} from 'src/utils/common';

// login api call
export const login = async (payload: LoginRequest, isRemember: boolean) => {
  const response = await API.Post<LoginRequest, LoginResponse>(
    REST_SUB_URL.LOGIN,
    {
      ...payload,
    },
  );
  if (response.status) {
    if (response?.data?.token && response.data?.data.role === USER_TYPE.ADMIN) {
      if (isRemember) {
        setTokenToLS(response?.data?.token);
        setRememberMeToLS(isRemember);
      } else {
        setTokenToSS(response?.data?.token);
        setRememberMeToSS(false);
      }
    } else if (response.data?.data.role !== USER_TYPE.ADMIN) {
      toast.error(strings.loginError.authError);
      return false;
    }
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response.data?.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

// forgot password api call
export const forgotPassword = async (payload: forgotPasswordRequest) => {
  const response = await API.Post<
    forgotPasswordRequest,
    forgotPasswordResponse
  >(REST_SUB_URL.FORGOT_PASSWORD, {
    ...payload,
  });
  if (response.status) {
    if (response?.data?.data.role === USER_TYPE.ADMIN) {
      toast.success(response?.data?.message || COMMON_SUCCESS);
      return true;
    }
    toast.error(strings.loginError.emailNotFound);
    return false;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

// otp api call
export const otp = async (payload: OtpRequest) => {
  const response = await API.Post<OtpRequest, OtpResponse>(REST_SUB_URL.OTP, {
    ...payload,
  });
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

// resend otp api call
export const resentOTP = async (payload: ResendOtpRequest) => {
  const response = await API.Post<ResendOtpRequest, OtpResponse>(
    REST_SUB_URL.RESEND_OTP,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

// reset password api call
export const resetPassword = async (payload: resetPasswordRequest) => {
  const response = await API.Post<resetPasswordRequest, resetPasswordResponse>(
    REST_SUB_URL.RESET_PASSWORD,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
export const signInAsUser = async (payload: IEmailField) => {
  const response = await API.Post<IEmailField, signInASUserResponse>(
    REST_SUB_URL.SIGNIN_AS_USER,
    {
      ...payload,
    },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
