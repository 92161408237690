import { useState } from 'react';

// import filterIcon from '@/assets/svgs/filterIcon.svg';
import Table from '@/components/FilterTable';
import { getUserDashboardColumns } from '@/components/FilterTable/Columns';
import Loader from '@/components/Loader';
import { Typography } from '@/components/Typography';
// import { Button } from '@/components/ui/button';
import useDashboardContext from '@/hooks/useDashboard';
import { strings } from '@/locales';

import withProtectedRoute from 'src/components/ProtectedRoute';

const Dashboard: React.FC = () => {
  const [dropdownOpenId, setDropdownOpenId] = useState<number | null>(null);
  const {
    dataList,
    setDataList,
    totalPages,
    isLoading,
    setCurrentPage,
    currentPage,
  } = useDashboardContext();

  const dashboardColumns = getUserDashboardColumns(
    dropdownOpenId,
    setDropdownOpenId,
    dataList,
    setDataList,
  );
  return (
    <div className='py-6'>
      <div className='flex justify-between mx-1 items-center'>
        <Typography variant='p' className='font-semibold text-xl'>
          {strings.dashboard.heading}
        </Typography>
        {/* <div className='flex gap-3'>
          <Button
            variant={'primary'}
            className='rounded-full p-3 sm:p-5 font-medium text-base'
          >
            <img src={filterIcon} alt='filter icon' className='mr-2 sm:mr-4' />
            {strings.dashboard.filter}
          </Button>
        </div> */}
      </div>
      <div>
        {isLoading ? (
          <div className='flex items-center justify-center h-40'>
            <Loader />
          </div>
        ) : (
          <Table
            data={dataList}
            columns={dashboardColumns}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};
export default withProtectedRoute(Dashboard);
