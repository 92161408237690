import { HiMenuAlt2 } from 'react-icons/hi';

import useAuthContext from '@/hooks/useAuth';
import { strings } from '@/locales';

import UserData from './HeaderUserData';
import { Typography } from './Typography';

interface HeaderProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Header: React.FC<HeaderProps> = ({ isOpen, toggleSidebar }) => {
  const { auth } = useAuthContext();
  const { user } = auth ?? {};

  return (
    <nav className='bg-darkGray rounded-2xl w-full h-16 flex items-center'>
      <div className='px-3 py-3 lg:px-5 lg:pl-3 w-full'>
        <div className='flex items-center justify-between'>
          <button
            onClick={toggleSidebar}
            aria-controls='logo-sidebar'
            type='button'
            className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:focus:ring-gray-600'
          >
            <span className='sr-only'>{isOpen ? 'Close' : 'Open'}</span>
            <HiMenuAlt2
              className='size-7 sm:min-w-11 sm:min-h-11'
              color='#B3B3B3'
            />
          </button>
          <div className='flex items-center justify-between w-full text-white'>
            <div className='flex items-center sm:ms-3'>
              <Typography className='sm:text-xl font-semibold text-white'>
                {strings.header.Heading}
              </Typography>
            </div>
            <div className='flex justify-between min-w-52 items-center'>
              <UserData user={user} userRole={strings.header.admin} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
