/* eslint-disable max-lines */
import React from 'react';
import { IoIosStar } from 'react-icons/io';
import { MdArrowForwardIos } from 'react-icons/md';
import { RxDownload } from 'react-icons/rx';
import dayjs from 'dayjs';

import { userDataType } from '@/@types';
import { IAgreement } from '@/@types/agreementTypes';
import { CARD_TYPE, PAYMENT_STATUS, USER_TYPE } from '@/constants/common';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { AgreementSelectOption } from '@/pages/Agreement/AgreementSelectOption';
import { SelectOption } from '@/pages/Dashboard/TableSelectOption';
import { downloadInvoice } from '@/utils/common';
import { ColumnDef } from '@tanstack/react-table';

import AvatarUser from 'src/assets/images/AvatarUser.png';

import CheckboxInput from '../Checkbox';

import { CardColumn, Status } from './CellComponents';

export interface IColumn {
  amount: string;
  isBanned: boolean;
  role: string;
  avatar: string | undefined;
  isPosted: boolean;
  user: { firstName: string; lastName: string; avatar: string };
  comment: string;
  joinedAt: string;
  description: string;
  createdAt: string;
  rating: number;
  review: string;
  name: string;
  image: string | undefined;
  lastName: string;
  firstName: string;
  _id: number;
  cardNumber: number;
  phone?: string;
  downloadUrl: string;
  status: PAYMENT_STATUS;
  cardType: CARD_TYPE;
  last4(arg0: string, last4: unknown): unknown;
  id?: string;
  accessorKey?: string;
  header: string | (() => JSX.Element) | (() => string);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell?: ({ row }: { row: any }) => JSX.Element;
}

export const getUserDashboardColumns = (
  dropdownOpenId: number | null,
  setDropdownOpenId: {
    (value: React.SetStateAction<number | null>): void;
  },
  dataList: userDataType[],
  setDataList: React.Dispatch<React.SetStateAction<userDataType[]>>,
): ColumnDef<IColumn>[] => {
  const baseColumns: ColumnDef<IColumn>[] = [
    {
      id: 'name',
      header: strings.dashboard.name,
      cell: (row) => (
        <div className='flex min-w-32 gap-2 items-center'>
          <div className='w-8 h-8 rounded-full'>
            <img
              src={row.row.original.avatar || AvatarUser}
              alt={row.row.original.firstName}
              className='border-none rounded-full w-8 h-8'
            />
          </div>
          {row.row.original.firstName}
        </div>
      ),
    },
    {
      accessorKey: 'email',
      header: strings.dashboard.email,
    },
    {
      accessorKey: 'role',
      header: strings.dashboard.role,
      cell: (row) => (
        <div
          className={cn(
            ' flex items-center py-0.5 capitalize rounded-full justify-center w-20',
            {
              'opacity-50 ': row.row.original.isBanned,
              'bg-greenishWhite text-charmedGreen':
                row.row.original.role === USER_TYPE.ADMIN,
              'bg-lightSky text-homeWorld ':
                row.row.original.role === USER_TYPE.USER,
            },
          )}
        >
          {row.row.original.role}
        </div>
      ),
    },
    {
      accessorKey: 'joinedAt',
      header: strings.dashboard.joindAt,
      size: 100,
      cell: (row) => dayjs(row.row.original.createdAt).format('YYYY-MM-DD'),
    },
    {
      accessorKey: 'country',
      header: strings.dashboard.country,
    },
    {
      id: 'cardNumber',
      header: strings.dashboard.cardNumber,
      cell: (row) => (
        <div>
          {row.row.original.cardNumber
            ? `*******${String(row.row.original.cardNumber || '00000').slice(
                -4,
              )}`
            : 'N/A'}
        </div>
      ),
    },
    {
      accessorKey: 'totalAmount',
      header: strings.dashboard.totalAmount,
    },
    {
      id: 'status',
      header: strings.dashboard.status,
      cell: (row) => (
        <Status value={row.row.original.status as PAYMENT_STATUS} />
      ),
    },
    {
      id: 'select',
      cell: (cellInfo) => (
        <SelectOption
          id={cellInfo.row.original._id}
          dropdownOpenId={dropdownOpenId}
          setDropdownOpenId={setDropdownOpenId}
          dataList={dataList}
          setDataList={setDataList}
        />
      ),

      size: 20,
    },
  ];

  return baseColumns;
};
export const getPaymentHistoryColumns = (): ColumnDef<IColumn>[] => {
  const baseColumns: ColumnDef<IColumn>[] = [
    {
      id: 'pymenet',
      header: strings.dashboard.paymentMethod,
      cell: (row) => (
        <CardColumn
          cardType={row.row.original.cardType}
          cardNumber={row.row.original.last4}
        />
      ),
    },
    {
      id: 'amount',
      header: strings.dashboard.amount,
      cell: (row) => `$${row.row.original.amount}`,
    },
    {
      id: 'date',
      header: strings.dashboard.date,
      cell: (row) => dayjs(row.row.original.joinedAt).format('YYYY-MM-DD'),
    },
    {
      id: 'status',
      header: strings.dashboard.status,
      cell: (row) => (
        <Status value={row.row.original.status as PAYMENT_STATUS} />
      ),
    },
    {
      id: 'invoice',
      header: strings.dashboard.invoice,
      cell: (row) => (
        <div
          onClick={() => downloadInvoice(row.row.original.downloadUrl)}
          className='cursor-pointer py-3 text-homeWorld flex gap-1 items-center'
        >
          <RxDownload size={18} />
          {strings.userDetailScreen.DOWNLOAD}
        </div>
      ),
    },
  ];

  return baseColumns;
};
export const getReviewsColumns = (
  showFullReview: number | null,
  handleShowFullReview: (row: { row: { original: { _id: number } } }) => void,
): ColumnDef<IColumn>[] => {
  const baseColumns: ColumnDef<IColumn>[] = [
    {
      id: 'check',
      header: ({ table }) => (
        <CheckboxInput
          label='select-all'
          checked={table.getIsAllRowsSelected()}
          setChecked={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <CheckboxInput
          label='select'
          checked={row.getIsSelected()}
          setChecked={row.getToggleSelectedHandler()}
        />
      ),
    },
    {
      id: 'name',
      header: strings.dashboard.name,
      cell: (row) => (
        <div
          onClick={() => handleShowFullReview(row)}
          className='flex w-48 gap-2 items-center'
        >
          <MdArrowForwardIos
            size={20}
            className={cn('transition-transform duration-500 ease-in-out', {
              'rotate-[90deg]': showFullReview === row.row.original._id,
            })}
          />
          <img
            src={row?.row?.original?.user?.avatar || AvatarUser}
            alt={row.row.original.name}
            className='w-8 h-8 border-none rounded-full'
          />
          {row?.row?.original?.user?.firstName &&
          row?.row?.original?.user?.lastName
            ? `${row?.row?.original?.user?.firstName} ${row?.row?.original?.user?.lastName}`
            : strings.sidebar.users}
        </div>
      ),
    },
    {
      id: 'comment',
      header: strings.review.review,
      cell: (row) =>
        showFullReview === row.row.original._id ? (
          <div
            className={cn('py-3 w-96 md:w-[500px]', {
              ' text-center': row.row.original.comment.length < 70,
            })}
          >
            {row.row.original.comment}
          </div>
        ) : (
          <div
            className={cn('py-3 w-96 md:w-[500px]', {
              ' text-center': row.row.original.comment.length < 70,
            })}
          >
            {row.row.original.comment.length <= 70
              ? row.row.original.comment
              : `${row.row.original.comment.slice(0, 70)}...`}
          </div>
        ),
    },
    {
      id: 'rating',
      header: strings.review.rating,
      cell: (row) => (
        <div className='py-3 flex gap-1 items-center justify-center'>
          {Array.from({ length: row.row.original.rating as number }).map(
            (_, index: number) => (
              <IoIosStar key={index} size={21} className='fill-starYellow' />
            ),
          )}
        </div>
      ),
    },
  ];

  return baseColumns;
};
export const getAgreementColumns = (
  dropdownOpenId: number | null,
  setDropdownOpenId: {
    (value: React.SetStateAction<number | null>): void;
  },
  setDataList: React.Dispatch<React.SetStateAction<IAgreement[]>>,
  dataList: IAgreement[],
): ColumnDef<IColumn>[] => {
  const baseColumns: ColumnDef<IColumn>[] = [
    {
      id: 'name',
      header: strings.agreement.name,
      cell: (row) => (
        <div className='flex min-w-32 gap-2 items-center'>
          <img
            src={row.row.original.avatar ?? AvatarUser}
            alt={row.row.original.user.firstName}
            className='w-8 h-8'
          />
          {row.row.original.user.firstName}
        </div>
      ),
    },
    {
      id: 'agreement',
      header: strings.agreement.agreement,
      cell: (row) => {
        return (
          <div className='py-3 w-96 md:w-[500px]'>
            {row.row.original.description.slice(0, 100)}...
          </div>
        );
      },
    },
    {
      accessorKey: 'publishedAt',
      header: strings.agreement.pubAt,
      cell: (row) => dayjs(row.row.original.createdAt).format('YYYY-MM-DD'),
    },
    {
      id: 'select',
      cell: (cellInfo) => (
        <AgreementSelectOption
          id={cellInfo.row.original._id}
          dropdownOpenId={dropdownOpenId}
          setDropdownOpenId={setDropdownOpenId}
          setDataList={setDataList}
          dataList={dataList}
        />
      ),

      size: 20,
    },
  ];

  return baseColumns;
};
