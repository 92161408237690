import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormWatch,
} from 'react-hook-form';

import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { INPUT_ERRORS } from '@/constants/feedback';
import { strings } from '@/locales';

export interface ISendEmailField {
  emailText: string;
}
type sendEmailModalProps = {
  control: Control<ISendEmailField>;
  errors: FieldErrors<ISendEmailField>;
  handleSubmit: UseFormHandleSubmit<ISendEmailField, undefined>;
  showSendEmailModalOpen: boolean;
  setShowSendEmailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateButton: SubmitHandler<ISendEmailField>;
  watch: UseFormWatch<ISendEmailField>;
};

const SendEmailModal: React.FC<sendEmailModalProps> = ({
  control,
  watch,
  errors,
  handleSubmit,
  handleUpdateButton,
  showSendEmailModalOpen,
  setShowSendEmailModalOpen,
}) => {
  const emailText = watch('emailText');
  return (
    <Modal
      title={strings.sendEmail.title}
      btnText={strings.sendEmail.buttonText}
      handleBtn={handleSubmit(handleUpdateButton)}
      open={showSendEmailModalOpen}
      buttonDisabled={!emailText}
      setOpen={setShowSendEmailModalOpen}
    >
      <div>
        <div className='text-white mb-5 '>
          <div className='flex flex-col gap-y-2 flex-grow'>
            <Label className='text-base mt-2 mb-1'>
              {strings.sendEmail.emailText}
            </Label>
            <Controller
              control={control}
              name='emailText'
              rules={{
                required: INPUT_ERRORS.TEXT.required,
              }}
              render={({ field: { onChange, value, name } }) => (
                <Textarea
                  className='bg-darkGray border-none focus-visible:ring-0 focus-visible:ring-offset-0 rounded-[14px] p-3 text-[15px] w-full  min-h-72'
                  placeholder={strings.sendEmail.placeholder}
                  value={value}
                  name={name}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {errors.emailText ? (
            <Typography variant='p' className='text-red-500 text-base'>
              {String(errors.emailText?.message)}
            </Typography>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default SendEmailModal;
