import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAuthContext from '@/hooks/useAuth';
import { protectedRoutes, ROUTES } from '@/routes';

import { USER_TYPE } from 'src/constants/common';
import { getAuthToken } from 'src/utils/common';

const withProtectedRoute = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const AuthenticatedRoute: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const token = getAuthToken();
    const { auth } = useAuthContext();

    useEffect(() => {
      if (!token || auth?.user.role !== USER_TYPE.ADMIN) {
        navigate(ROUTES.LOGIN);
      }
      if (token) {
        if (protectedRoutes.includes(pathname)) {
          navigate(pathname);
        }
      }
    }, [token, auth]);

    return <WrappedComponent {...props} />;
  };

  return AuthenticatedRoute;
};

export default withProtectedRoute;
