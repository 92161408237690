import { Link } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { LinkType } from '@/services/types';

type SidebarProps = {
  link: LinkType;
  activeLink: string;
  handleSidebarLinkClick: (link: string) => void;
};

const Sidebar: React.FC<SidebarProps> = ({
  link,
  activeLink,
  handleSidebarLinkClick,
}) => {
  return (
    <>
      {link.subMenu && activeLink === link.title ? (
        <div className='flex justify-end mt-2'>
          <div className='ml-12 w-full'>
            {link.subMenu.map((item: { link: string; title: string }) => (
              <Link key={item.title} to={item.link}>
                <div
                  className={cn(
                    'flex items-center px-4 py-2 text-white group hover:bg-eveningEmrald rounded-full',
                    {
                      'bg-eveningEmrald ': location.pathname === item.link,
                      'bg-transparentWhite ': location.pathname !== item.link,
                    },
                  )}
                  onClick={() => handleSidebarLinkClick(item.link || '')}
                >
                  <div className='w-2 h-2 rounded-full bg-white mr-2 group-hover:bg-gradient-bg' />
                  {item.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Sidebar;
