import toast from 'react-hot-toast';

import { ResponseType } from '@/@types/authTypes';
import { NewsType } from '@/@types/newsType';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from '@/constants/feedback';
import { REST_SUB_URL } from '@/constants/url';

import {
  DeleteFilterResponseType,
  INewsData,
  INewsDataResponse,
  NewsPostResponseType,
} from './types';

export const getAllNews = async () => {
  const response = await API.Get<ResponseType<NewsType[]>>(
    REST_SUB_URL.GET_ALL_NEWS,
  );
  return response?.data;
};

export const postNews = async (payload: INewsData) => {
  const formData = new FormData();
  formData.append('file', payload.file as File);
  formData.append('title', payload.title);
  formData.append('description', payload.description);
  formData.append('isPosted', payload.isPosted.toString());
  const response = await API.Post<FormData, ResponseType<INewsDataResponse>>(
    REST_SUB_URL.CREATE_NEWS,
    formData,
    true,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response?.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};

export const updateNews = async (newsId: string, payload: INewsData) => {
  const formData = new FormData();
  formData.append('file', payload.file as File);
  formData.append('title', payload.title);
  formData.append('description', payload.description);
  formData.append('isPosted', payload.isPosted.toString());
  const response = await API.Put<ResponseType<INewsData>, FormData>(
    `${REST_SUB_URL.UPDATE_NEWS}/${newsId}`,
    formData,
    true,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};

export const deleteNews = async (filterId: string) => {
  const response = await API.Delete<DeleteFilterResponseType>(
    `${REST_SUB_URL.DELETE_NEWS}/${filterId}`,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  return null;
};

export const updateNewsStatus = async (newsId: string, isPosted: boolean) => {
  const response = await API.Put<NewsPostResponseType, { isPosted: boolean }>(
    `${REST_SUB_URL.POST_NEWS_STATUS}/${newsId}`,
    { isPosted },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
