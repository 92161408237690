import { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { Link, useLocation } from 'react-router-dom';

import logo from '@/assets/svgs/app-logo.svg';
import { SIDEBAR_LINKS } from '@/constants/common';
import { cn } from '@/lib/utils';
import { ROUTES } from '@/routes';
import { LinkType, SidebarProps } from '@/services/types';

import SidebarLogoutSection from './SidebarLogoutSection';
import SidebarSubMenu from './SidebarSubMenu';

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState<string>(location.pathname);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes(ROUTES.USER_DETAIL)) {
      setActiveLink(ROUTES.DASHBOARD);
    } else if (
      pathname.includes(ROUTES.ADD_NEWS_AND_VIDEOS) ||
      pathname.includes(ROUTES.NEWS_DETAIL)
    ) {
      setActiveLink(ROUTES.NEWS_LIST);
    } else if (pathname.includes(ROUTES.FINVIZ)) {
      setActiveLink('Configuration');
    }
  }, [location]);

  const handleSidebarLinkClick = (link: string) => {
    setActiveLink(link);
    toggleSidebar();
  };

  const handleSubMenuClick = (link: string) => {
    setActiveLink(link);
  };

  const renderSidebarLinks = () =>
    SIDEBAR_LINKS.map((link: LinkType) => {
      const isActive =
        activeLink === link.link || (activeLink === link.title && !link.link);
      const hasSubMenu = link.subMenu?.some((item) => activeLink === item.link);

      return (
        <div
          key={link.title}
          className={cn('relative', {
            'bg-homeWorld h-[46px] rounded-full w-full': isActive,
          })}
        >
          {link.link ? (
            <li
              className={cn('rounded-full', {
                'bg-eveningEmrald absolute top-[-1px] w-[98%]': isActive,
              })}
            >
              <Link
                to={link.link}
                className='flex items-center px-4 py-3 text-gray-900 dark:text-white group'
                onClick={() => handleSidebarLinkClick(link.link || '')}
              >
                <link.icon
                  colorOne={isActive ? '#1B9FDF' : '#fff'}
                  colorTwo={isActive ? '#AC7AFD' : '#fff'}
                />
                <span
                  className={cn('ml-5 text-white', {
                    'font-semibold': isActive,
                  })}
                >
                  {link.title}
                </span>
              </Link>
            </li>
          ) : (
            <li
              className={cn('rounded-full', {
                'absolute top-[-1px] w-[98%]': isActive || hasSubMenu,
              })}
            >
              <div
                className={cn(
                  'flex rounded-full items-center px-4 py-3 text-gray-900 dark:text-white group',
                  {
                    '!bg-eveningEmrald': isActive || hasSubMenu,
                    'bg-transparentWhite': !isActive && !hasSubMenu,
                  },
                )}
                onClick={() => handleSubMenuClick(link.title)}
              >
                <link.icon
                  colorOne={isActive || hasSubMenu ? '#1B9FDF' : '#fff'}
                  colorTwo={isActive || hasSubMenu ? '#AC7AFD' : '#fff'}
                />
                <span
                  className={cn('ml-5 text-white', {
                    'font-semibold': isActive && !link.link,
                  })}
                >
                  {link.title}
                </span>
              </div>
              <SidebarSubMenu
                link={link}
                activeLink={activeLink}
                handleSidebarLinkClick={handleSidebarLinkClick}
              />
            </li>
          )}
        </div>
      );
    });

  return (
    <aside
      id='logo-sidebar'
      className={cn(
        'fixed top-0 left-0 sm:translate-x-0 z-40 h-screen transition-transform sm:pl-6 sm:py-3',
        { 'translate-x-0': isOpen, '-translate-x-full': !isOpen },
      )}
      aria-label='Sidebar'
    >
      <div className='h-full px-2 pb-4 flex flex-col justify-between relative overflow-y-auto bg-darkGray w-60 sm:rounded-2xl py-2'>
        <IoCloseSharp
          color='#fff'
          size={20}
          className='right-2 absolute top-3 sm:hidden'
          onClick={toggleSidebar}
        />
        <div>
          <div className='w-full flex justify-center'>
            <img src={logo} alt='Logo' />
          </div>
          <ul className='space-y-3 font-medium mt-8'>{renderSidebarLinks()}</ul>
        </div>
        <SidebarLogoutSection />
      </div>
    </aside>
  );
};

export default Sidebar;
