import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { NewsType } from '@/@types/newsType';
import Loader from '@/components/Loader';
import withProtectedRoute from '@/components/ProtectedRoute';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { COMMON_ERROR } from '@/constants/feedback';
import { NEWS_SECTION } from '@/constants/newsAndVideos';
import { ROUTES } from '@/routes';
import { deleteNews, getAllNews } from '@/services/news';

import NewsCard from './components/NewsCard';

const NewsList = () => {
  const [dataList, setDataList] = React.useState<NewsType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleNavigateToDetailPage = (newsDetailData: NewsType) => {
    navigate(`${ROUTES.NEWS_DETAIL}/${newsDetailData._id}`);
  };
  const handleEditClick = (id: string, e: React.MouseEvent) => {
    e.stopPropagation();
    const dataForEdit = dataList.find((item) => item._id === id);
    navigate(ROUTES.ADD_NEWS_AND_VIDEOS, { state: { dataForEdit } });
  };

  const handleTrashClick = async (id: string) => {
    const response = await deleteNews(id);
    if (response?.status) {
      setDataList((prev) => prev.filter((item) => item._id !== id));
    }
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchAllNews = async () => {
      try {
        const response = await getAllNews();
        setDataList((response?.data as NewsType[]) || []);
        setIsLoading(false);
      } catch (error) {
        toast.error(COMMON_ERROR);
      }
    };
    fetchAllNews();
  }, []);

  return (
    <div className='my-6 ml-[16px] mr-6'>
      <div className='flex justify-between mx-1 flex-col md:flex-row gap-3 md:gap-0 md:items-center'>
        <Typography variant='p' className='font-semibold md:text-xl'>
          {NEWS_SECTION.title}
        </Typography>
        <div className='flex gap-3'>
          <Button
            variant={'primary'}
            onClick={() => navigate(ROUTES.ADD_NEWS_AND_VIDEOS)}
            className='rounded-full p-3 sm:p-5 font-medium text-base'
          >
            <IoIosAdd className='mr-2 sm:mr-4' size={24} />
            {NEWS_SECTION.title}
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className='flex items-center justify-center h-40'>
          <Loader />
        </div>
      ) : (
        <>
          {dataList.length > 0 ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] mt-[30px]'>
              {dataList.map((item: NewsType) => (
                <NewsCard
                  id={item._id}
                  key={`${item._id}`}
                  title={item.title}
                  isPosted={item.isPosted}
                  file={item.file}
                  date={
                    item?.createdAt
                      ? dayjs(item?.createdAt).format('YYYY-MMM-DD')
                      : 'N/A'
                  }
                  onClick={() => handleNavigateToDetailPage(item)}
                  handleEditClick={handleEditClick}
                  handleTrashClick={handleTrashClick}
                />
              ))}
            </div>
          ) : (
            <Typography className='text-xl font-normal mt-20 text-borderWhite text-center w-full'>
              {NEWS_SECTION.noData}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default withProtectedRoute(NewsList);
