/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';

import { JSONParse } from './jsonparse';

export const isUndefined = (value: any) => value === undefined;
export const isDefined = (value: any) => value !== undefined;
export const isNull = (value: any) => value === null;

export const getFormattedDate = (date: string) => {
  const _date = dayjs(date);
  const formattedDate = _date.format('YYYY-MM-DD');
  return formattedDate;
};
export const EmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

export const maskEmailAddress = (email: string) => {
  if (email) {
    return email.replace(
      /^(.)(.*)(.@.*)$/,
      (_, a, b, c) => a + b.replace(/./g, '*') + c,
    );
  }
  return '';
};
export const downloadInvoice = (url: string) => {
  window.open(url, '_blank');
};

export const getTokenFromLS = () => {
  if (typeof localStorage !== 'undefined') {
    return JSONParse(localStorage.getItem('token') as string);
  }
  return null;
};

export const setTokenToSS = (token: string) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('token', JSON.stringify(token));
  }
};

export const getTokenFromSS = () => {
  if (typeof sessionStorage !== 'undefined') {
    return JSONParse(sessionStorage.getItem('token') as string);
  }
  return null;
};

export const checkTokenValidity = () => {
  const token = getTokenFromLS() || getTokenFromSS();
  if (!token) {
    return false;
  }
  return true;
};

export const getAuthToken = () => {
  const token = getTokenFromLS() || getTokenFromSS();
  return token || '';
};

export const setRememberMeToLS = (isRememberMe: boolean) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('isRememberMe', JSON.stringify(isRememberMe));
  }
};
export const setRememberMeToSS = (isRememberMe: boolean) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem('isRememberMe', JSON.stringify(isRememberMe));
  }
};

export const setTokenToLS = (token: string) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('token', JSON.stringify(token));
  }
};

export const removeAuthToken = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('token');
  localStorage.removeItem('isRememberMe');
  sessionStorage.removeItem('isRememberMe');
};
export const truncateText = (text: string) =>
  text.length > 20 ? `${text.slice(0, 20)}...` : text;
