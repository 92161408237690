import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';

import { IAgreementInput } from '@/@types/agreementTypes';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { INPUT_ERRORS } from '@/constants/feedback';
import { strings } from '@/locales';

type agreementModalProps = {
  control: Control<IAgreementInput>;
  errors: FieldErrors<IAgreementInput>;
  handleSubmit: UseFormHandleSubmit<IAgreementInput, undefined>;
  showEditModal: boolean;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleUpdateButton: SubmitHandler<IAgreementInput>;
};

const UpdateAgreementModal: React.FC<agreementModalProps> = ({
  control,
  errors,
  handleSubmit,
  handleUpdateButton,
  showEditModal,
  setShowEditModal,
}) => {
  return (
    <Modal
      title={strings.agreement.updateAgreement}
      btnText={strings.agreement.updateAgreement}
      handleBtn={handleSubmit(handleUpdateButton)}
      open={showEditModal}
      setOpen={setShowEditModal}
    >
      <div>
        <div className='text-white mb-5 '>
          <div className='flex flex-col gap-y-2 flex-grow'>
            <Label className='text-base mt-2 mb-1'>
              {strings.agreement.title}
            </Label>
            <Controller
              control={control}
              name='description'
              rules={{
                required: INPUT_ERRORS.AGREEMENT.required,
                validate: (value) => {
                  if (!value.includes('/*name*/')) {
                    return 'Please include /*name*/ in the agreement.';
                  }
                  if (!value.includes('/*location*/')) {
                    return 'Please include /*location*/ in the agreement.';
                  }
                  return true;
                },
              }}
              render={({ field: { onChange, value, name } }) => (
                <Textarea
                  className='bg-darkGray border-none focus-visible:ring-0 focus-visible:ring-offset-0 rounded-[14px] p-3 text-[15px] w-full  h-60 overflow-auto'
                  placeholder={strings.agreement.addAgreement}
                  value={value}
                  name={name}
                  onChange={onChange}
                />
              )}
            />
            <Typography className='text-base'>
              <strong>{strings.agreement.note}</strong>{' '}
              {strings.agreement.noteText}
            </Typography>
          </div>
          {errors.description ? (
            <Typography variant='p' className='text-red-500 text-base'>
              {String(errors.description?.message)}
            </Typography>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default UpdateAgreementModal;
