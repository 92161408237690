import Avatar from '@/assets/images/Avatar.png';
import AgreemenetIcon from '@/assets/svgs/agreement';
import ConfigurationIcon from '@/assets/svgs/configuration';
import NewsAndVediosIcon from '@/assets/svgs/newsAndVedios';
import ReviewIcoon from '@/assets/svgs/review';
import StockIcon from '@/assets/svgs/stock';
import UserIcon from '@/assets/svgs/user';
import UserIconTwo from '@/assets/svgs/userIconTwo';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
export const HEADER_TEXT = { LOGOUT: 'Logout', FREE: 'Free', USER: 'User' };

export const SIDEBAR_LINKS = [
  { title: strings.sidebar.users, icon: UserIcon, link: ROUTES.DASHBOARD },
  {
    title: strings.sidebar.signInAsUser,
    icon: UserIconTwo,
    link: ROUTES.SIGN_IN_AS_USER,
  },
  { title: strings.sidebar.reviews, icon: ReviewIcoon, link: ROUTES.REVIEWS },
  // { title: strings.sidebar.stock, icon: StockIcon, link: ROUTES.STOCK },
  {
    title: strings.sidebar.newsAndVideos,
    icon: NewsAndVediosIcon,
    link: ROUTES.NEWS_LIST,
  },
  {
    title: strings.sidebar.agreement,
    icon: AgreemenetIcon,
    link: ROUTES.AGREEMENT,
  },
  {
    title: strings.sidebar.configuration,
    icon: ConfigurationIcon,
    subMenu: [{ title: strings.sidebar.finviz, link: ROUTES.FINVIZ }],
  },
];
export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
export const videoExtensions = ['mp4'];
export const HEADER_DROPDOWN = [
  { title: strings.header.profile, icon: StockIcon, link: ROUTES.PROFILE },
];
export enum PAYMENT_STATUS {
  PAID = 'paid',
  DRAFT = 'draft',
  OPEN = 'open',
  ACTIVE = 'active',
  TRIALING = 'trialing',
}
export enum TABLE_COLUMN_ENUM {
  STATUS = 'Status',
  ROLE = 'Role',
}
export const enum USER_TYPE {
  ADMIN = 'admin',
  USER = 'user',
}

export const STOCK_TABLE_OPTIONS = [
  { title: strings.dashboard.tableOptions.view, link: ROUTES.USER_DETAIL },
  { title: strings.dashboard.tableOptions.delete, link: '/' },
  { title: strings.dashboard.tableOptions.sendEmail, link: '/' },
];
export const PAGINATE_TEXT = {
  previous: 'Previous',
  next: 'Next',
};
export enum USER_PLAN_TYPE {
  BASIC = 'Basic',
  PREMIUM = 'Premium',
}
export enum CARD_TYPE {
  VISA = 'visa',
  MASTER = 'master',
}
export const DUMMY_USER_CARD_INFO = {
  cardType: CARD_TYPE.MASTER,
  last4: '1234',
  expMonth: '09',
  expYear: '2024',
};

export type CardInfo = {
  cardType: string;
  last4: string;
  expMonth: number | string;
  expYear: number | string;
};

export const PAYMENT_HISTORY_TABLE_DATA = [
  {
    amount: '2000',
    date: '2024-04-02',
    status: PAYMENT_STATUS.PAID,
    cardType: CARD_TYPE.VISA,
    downloadUrl: 'https://www.google.com',
    last4: '1234',
  },
  {
    amount: '2000',
    date: '2024-04-02',
    status: PAYMENT_STATUS.DRAFT,
    cardType: CARD_TYPE.MASTER,
    downloadUrl: 'https://www.google.com',
    last4: '4234',
  },
  {
    amount: '2000',
    date: '2024-04-02',
    status: PAYMENT_STATUS.PAID,
    cardType: CARD_TYPE.VISA,
    downloadUrl: 'https://www.google.com',
    last4: '1234',
  },
  {
    amount: '2000',
    date: '2024-04-02',
    status: PAYMENT_STATUS.PAID,
    cardType: CARD_TYPE.MASTER,
    downloadUrl: 'https://www.google.com',
    last4: '1234',
  },
];
export const SORTING_ITEMS = [
  { value: 'all', label: 'All' },
  { value: 'first', label: 'Pin First' },
  { value: 'last', label: 'Pin Last' },
];

export const USER_TYPE_OPTIONS = [
  { value: USER_TYPE.ADMIN, label: strings.userDetailScreen.admin },
  { value: USER_TYPE.USER, label: strings.userDetailScreen.user },
];
export const PAYMENT_STATUS_OPTIONS = [
  { value: PAYMENT_STATUS.PAID, label: strings.userDetailScreen.paid },
  { value: PAYMENT_STATUS.DRAFT, label: strings.userDetailScreen.draft },
  { value: PAYMENT_STATUS.OPEN, label: strings.userDetailScreen.open },
  { value: PAYMENT_STATUS.ACTIVE, label: strings.userDetailScreen.active },
];
export const AGREEMENT_TABLE_DATA = [
  {
    _id: 1,
    firstName: 'Michael',
    agreement:
      'I really love this app./*name*/ have the live flow subscription for $50 a month. I have made over $8k in the past two weeks using the live flow data. If you trade options, this is a MUST HAVE app.',
    publishedAt: '2024-04-02',
    image: Avatar,
  },
  {
    _id: 2,
    firstName: 'Michael',
    agreement:
      'I really love this app./*name*/ have the live flow subscription for $50 a month. I have made over $8k in the past two weeks using the live flow data. If you trade options, this is a MUST HAVE app.',
    publishedAt: '2024-04-02',
    image: Avatar,
  },
  {
    _id: 3,
    firstName: 'Michael',
    agreement:
      'I really love this app./*name*/ have the live flow subscription for $50 a month. I have made over $8k in the past two weeks using the live flow data. If you trade options, this is a MUST HAVE app.',
    publishedAt: '2024-04-02',
    image: Avatar,
  },
];
export enum NEWS_PUBLISH_STATUS {
  POSTED = 'posted',
  UNPOSTED = 'unposted',
}
