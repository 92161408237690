import { ResponseType, UserType } from '@/@types/authTypes';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/url';

export const getPersonalInfo = async () => {
  const response = await API.Get<ResponseType<UserType>>(
    REST_SUB_URL.GET_PROFILE,
  );
  return response?.data;
};
