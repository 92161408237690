import React from 'react';

import Macbook from '@/assets/images/macbook.png';
import logo from '@/assets/svgs/app-logo.svg';

import { Typography } from 'src/components/Typography';
import { LOGIN } from 'src/constants/auth';

interface IAuthWrapper {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<IAuthWrapper> = ({ children }) => {
  return (
    <div className='grid grid-cols-1 gap-6 md:grid-cols-2 min-h-[calc(100vh+80px)]'>
      <div className='justify-center align-middle flex flex-col w-full py-4 px-4 mt-20'>
        <div className='bg-transparentBlack rounded-xl p-6 lg:mb-36 mb-8 max-w-[450px] self-center z-50'>
          <Typography className='text-[24px] font-semibold mb-3'>
            {LOGIN.SUB_HEADING}
          </Typography>
          <Typography>{LOGIN.PARAGRAPH}</Typography>
        </div>
        <img src={Macbook} alt='login-image' className='w-full z-50' />
      </div>
      <div className=' bg-primaryBackground p-4 2xl:px-[160px] md:px-20 flex flex-col h-full z-50 pt-20 relative'>
        <div className='w-full flex justify-center'>
          <img src={logo} alt=' Logo' />
        </div>
        {children}
        <Typography className='text-[14px] font-semibold mt-6 lg:absolute lg:bottom-20 xl:bottom-40 2xl:bottom-60 self-center'>
          {LOGIN.FOOTER_TEXT}
        </Typography>
      </div>
    </div>
  );
};

export default AuthWrapper;
