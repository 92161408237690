import React, { useMemo } from 'react';
import { FieldErrors } from 'react-hook-form';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';

import { Label } from './ui/label';

interface FormFieldProps {
  title?: string;
  placeholder: string;
  labelCalssName?: string;
  value: string;
  onChange?: (value: string) => void;
  errors?: FieldErrors;
  isDisabled?: boolean;
  inputClassName?: string;
  options: {
    value: string;
    label: string;
  }[];
  listItemContainerClassName?: string;
  listItemClassName?: string;
}

const SelectInput: React.FC<FormFieldProps> = ({
  title,
  placeholder,
  options,
  value,
  labelCalssName,
  isDisabled,
  inputClassName,
  onChange,
  errors,
  listItemContainerClassName,
  listItemClassName,
}) => {
  const [selectedValue, setSelectedValue] = React.useState(value);

  const handleValueChange = (newValue: string) => {
    setSelectedValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const selectedOptionName = useMemo(() => {
    const newItem = options
      .filter(
        (item: { value: string; label: string }) =>
          item.value === selectedValue,
      )
      .map((item: { value: string; label: string }) => item.label)
      .toString();
    return newItem;
  }, [selectedValue]);

  return (
    <div>
      {title ? (
        <Label
          className={cn(
            'text-white text-base font-normal flex items-center gap-2 xl:gap-4 mt-2',
            {
              'opacity-70': isDisabled,
            },
            labelCalssName,
          )}
        >
          {title}
        </Label>
      ) : null}
      <Select onValueChange={handleValueChange} value={selectedValue}>
        <SelectTrigger
          className={cn(
            'text-white p-4 h-10 rounded-xl w-full ring-offset-0  bg-darkGray border-none focus:ring-2 focus:ring-white focus:ring-offset-0',
            {
              ' mt-2': title,
            },
            inputClassName,
          )}
          disabled={isDisabled}
        >
          <SelectValue placeholder={placeholder} className='text-base'>
            {selectedOptionName || placeholder}
          </SelectValue>
        </SelectTrigger>
        <SelectContent
          className={cn(
            'bg-darkGray border-none text-white rounded-xl relative',
            listItemContainerClassName,
          )}
        >
          {options.map((option: { value: string; label: string }) => (
            <SelectItem
              value={option.value}
              className={cn(
                'rounded-lg px-2 hover:!text-white focus:!text-white hover:!font-medium hover:!bg-skyCaptain focus:!bg-skyCaptain',
                listItemClassName,
              )}
              key={option.value}
            >
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {errors?.root?.message ? (
        <p className='text-red-500 text-xs mt-1'>{errors?.root?.message}</p>
      ) : null}
    </div>
  );
};

export default SelectInput;
