import React from 'react';

import { strings } from '@/locales';

interface Props {
  setShowViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  dropdownRef: React.RefObject<HTMLDivElement>;
}

const Options: React.FC<Props> = ({
  dropdownRef,
  setShowViewModal,
  setShowEditModal,
  setShowConfirmationModal,
}) => {
  return (
    <div
      className='bg-blueFantastic absolute right-[-2px] top-7 rounded-xl w-36 px-3 py-1 z-10'
      ref={dropdownRef}
    >
      <div
        onClick={() => setShowViewModal(true)}
        className='py-1 text-white cursor-pointer text-xs'
      >
        {strings.agreement.view}
      </div>
      <div
        onClick={() => setShowEditModal(true)}
        className='py-1 text-white cursor-pointer text-xs'
      >
        {strings.agreement.edit}
      </div>
      <div
        onClick={() => setShowConfirmationModal(true)}
        className='py-1 text-white cursor-pointer text-xs'
      >
        {strings.agreement.delete}
      </div>
    </div>
  );
};

export default Options;
