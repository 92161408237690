/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';

import { TableProps, userDataType } from '@/@types';
import { ReviewType } from '@/@types/reviewsType';
import { NEWS_SECTION } from '@/constants/newsAndVideos';
import { strings } from '@/locales';
import { getPostedReviews } from '@/services/reviews';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  HeaderGroup,
  RowPinningState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { Typography } from 'src/components/Typography';
import { calculateWidth, cn } from 'src/lib/utils';

import { Checkbox } from './CellComponents';
import { IStockRecord } from './makeData';
import Pagination from './Pagination';
import PinnedRow from './PinnedRow';
import SortRow from './SortRow';

const MyTable: React.FC<TableProps> = ({
  data,
  columns,
  isFilterTable,
  hasSortingOption,
  applyCenterClass,
  hasSort,
  setRowSelectionData,
  setCurrentPage,
  currentPage,
  totalPages,
}) => {
  const [rowPinning, setRowPinning] = useState<RowPinningState>({
    top: [],
  });
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [checkedRows, setCheckedRows] = useState<Record<string, boolean>>({});
  const [rowSelection, setRowSelection] = useState({});
  const [sortByPin, setSortByPin] = useState(true);

  const table = useReactTable<IStockRecord | userDataType | ReviewType>({
    columns: columns as unknown as ColumnDef<
      IStockRecord | userDataType | ReviewType
    >[],
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowPinning,
      sorting,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onRowPinningChange: setRowPinning,
    defaultColumn: {
      size: 80,
      minSize: 10,
      maxSize: 300,
    },
  });
  const handleSelect = (value: string) => {
    setSortByPin(value === 'first');
  };

  useEffect(() => {
    const selectedRowModel = table.getSelectedRowModel();
    if (setRowSelectionData) {
      setRowSelectionData(
        selectedRowModel.flatRows.map(
          (row: { original: userDataType | ReviewType | IStockRecord }) =>
            row.original as ReviewType,
        ),
      );
    }
  }, [rowSelection]);
  useEffect(() => {
    const fetchPosted = async () => {
      try {
        const response = await getPostedReviews();
        if (response?.data) {
          const responseIds = new Set(
            response.data.map((item: ReviewType) => item._id),
          );
          const indexObject: Record<number, boolean> = data
            .filter((item: { _id: string }) => responseIds.has(item._id))
            .reduce((acc: Record<number, boolean>, item: string) => {
              acc[data.indexOf(item)] = true;
              return acc;
            }, {});
          setRowSelection(indexObject);
        }
      } catch (error) {
        toast.error('err');
      }
    };
    fetchPosted();
  }, [data]);

  return (
    <div className='relative'>
      <div className='px-4 flex items-center justify-between'>
        <Typography>
          <span className='font-semibold'>
            {isFilterTable ? strings.dashboard.TABLE_NOTE.title : null}
            {}
          </span>{' '}
          {isFilterTable ? strings.dashboard.TABLE_NOTE.desc : null}
        </Typography>
        {hasSort ? <SortRow handleSelect={handleSelect} /> : null}
      </div>
      <div className='px-4 block max-w-full overflow-x-scroll overflow-y-hidden noScrollBar pb-16'>
        <table className='w-full border-separate border-spacing-y-3'>
          <thead>
            {table
              .getHeaderGroups()
              .map((headerGroup: HeaderGroup<IStockRecord>) => (
                <tr
                  key={headerGroup.id}
                  className='bg-wintersDay text-darkGray rounded-xl m-3'
                >
                  {headerGroup.headers.map((header, index: number, array) => (
                    <th
                      key={header.id}
                      className={cn('px-4 pr-2 md:py-3 font-bold', {
                        'rounded-r-2xl': index === array.length - 1,
                        'rounded-l-2xl': index === 0,
                        '!pr-0': header.column.id === 'check',
                      })}
                    >
                      {!header.isPlaceholder ? (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none flex items-center justify-center'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                          style={{
                            width: calculateWidth(header, table),
                          }}
                          className={cn(' flex items-center justify-center', {
                            'min-w-40':
                              header.column.columnDef.header &&
                              header.column.columnDef.header.length > 7,
                            'min-w-44': header.column.id === 'status',
                            '!w-full':
                              applyCenterClass && header.column.id !== 'check',
                          })}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {hasSortingOption ? (
                            <span>
                              {(header.column.columnDef.header &&
                                {
                                  asc: <TiArrowSortedUp />,
                                  desc: <TiArrowSortedDown />,
                                  default: <TiArrowUnsorted />,
                                }[header.column.getIsSorted() as string]) ?? (
                                <TiArrowUnsorted />
                              )}
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>
          <tbody className='dashboard-table'>
            {(sortByPin
              ? table.getTopRows().slice().reverse()
              : table.getTopRows()
            ).map((row) => (
              <PinnedRow key={row.id} row={row} />
            ))}
            {table.getRowModel().rows.map((row) => {
              if (row.getIsPinned()) return null;
              return (
                <tr
                  key={row.id}
                  className={cn(
                    'text-white border-transparentGreen bg-transparentGreen/5 border-2 ',
                    {
                      'borderGray bg-transparent noScroll': !isFilterTable,
                      'borderGreen': row.original.isPosted,
                    },
                  )}
                >
                  {row.original.isPosted}
                  {row.getVisibleCells().map((cell, index: number, array) => (
                    <td
                      key={cell.id}
                      className={cn('py-2 my-2 text-center', {
                        'rounded-r-2xl': index === array.length - 1,
                        'rounded-l-2xl': index === 0,
                        'text-gray-500': row.original.isBanned,
                      })}
                    >
                      {cell.column.id === 'checkbox' ? (
                        <Checkbox
                          checked={checkedRows[row.id] || false}
                          setChecked={(checked) =>
                            setCheckedRows({
                              ...checkedRows,
                              [row.id]: checked,
                            })
                          }
                        />
                      ) : null}
                      {cell.column.id !== 'checkbox' ? (
                        <div
                          className={cn(
                            'flex px-4 w-full items-center justify-center',
                            {
                              'pr-0': cell.column.id === 'check',
                            },
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </div>
                      ) : null}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {data.length ? (
          <>
            {currentPage ? (
              <div className='absolute righr-0 w-full px-4'>
                <Pagination
                  table={table}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage ?? 1}
                  totalPages={totalPages}
                />
              </div>
            ) : null}
          </>
        ) : (
          <Typography className='flex items-center justify-center text-base text-borderWhite'>
            {NEWS_SECTION.noData}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default MyTable;
