import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import FormField from '@/components/FormField';
import withUnauthenticatedRoute from '@/components/PublicRoutes';
import { ROUTES } from '@/routes';
import { resetPassword } from '@/services/auth';
import { ISetPasswordFields } from '@/services/types';

import Loader from 'src/components/Loader';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { SET_NEW_PASSWORD } from 'src/constants/auth';
import { INPUT_ERRORS } from 'src/constants/feedback';

import AuthWrapper from '../components/AuthWrapper';

const SetNewPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { state } = location as { state: { email: string } };
  const { email } = state || {};

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ISetPasswordFields>({
    mode: 'all',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const watchPassword = watch('password');

  const handleSetPassword: SubmitHandler<ISetPasswordFields> = async (data) => {
    const payload = {
      email,
      password: data.password,
    };
    setIsLoading(true);
    const response = await resetPassword(payload);
    setIsLoading(false);
    if (!response) return;
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <AuthWrapper>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {SET_NEW_PASSWORD.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto'>
        {SET_NEW_PASSWORD.NEW_TO_BO}{' '}
      </Typography>
      <Controller
        control={control}
        name='password'
        rules={{
          required: INPUT_ERRORS.PASSWORD.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={SET_NEW_PASSWORD.PASSWORD.title}
            placeholder={SET_NEW_PASSWORD.PASSWORD.placeholder}
            name={name}
            type='password'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='confirmPassword'
        rules={{
          required: INPUT_ERRORS.CONFIRM_PASSWORD.required,
          validate: (value) =>
            value === watchPassword || INPUT_ERRORS.CONFIRM_PASSWORD.match,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={SET_NEW_PASSWORD.CONFIRM_REMEMBER_ME.title}
            placeholder={SET_NEW_PASSWORD.CONFIRM_REMEMBER_ME.placeholder}
            name={name}
            type='password'
            value={value || ''}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Button
        variant='gradient'
        className='my-4'
        onClick={handleSubmit(handleSetPassword)}
      >
        {isLoading ? <Loader loading={isLoading} /> : SET_NEW_PASSWORD.SUBMIT}
      </Button>
    </AuthWrapper>
  );
};

export default withUnauthenticatedRoute(SetNewPassword);
