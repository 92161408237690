import React from 'react';
import { FieldErrors } from 'react-hook-form';

import { strings } from '@/locales';

import AddressSelect, { CityType } from './AddressSelect';

type CityFieldsProps = {
  errors: FieldErrors;
  value: string | undefined;
  onChange: () => void;
  cityList: CityType[];
  setCityId: (cityId: number) => void;
  cityId: number;
  isDisabled?: boolean;
};

const CitySelect: React.FC<CityFieldsProps> = ({
  errors,
  cityList,
  value,
  onChange,
  setCityId,
  cityId,
  isDisabled = false,
}) => {
  const handleCityChange = (fieldOnChange: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedCity = cityList.find(
        (city) => city.id === parseInt(e.target.value),
      );
      if (selectedCity) {
        setCityId(selectedCity.id);
      }
      fieldOnChange(e.target.value);
    };
  };
  return (
    <AddressSelect
      labelCalssName='text-base'
      isDisabled={isDisabled}
      onChange={handleCityChange(onChange)}
      value={value || cityId}
      errors={errors}
      dataList={cityList}
      title={strings.userDetailScreen.city}
      placeholder={strings.userDetailScreen.select}
    />
  );
};

export default CitySelect;
