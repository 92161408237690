import { SetStateAction, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { HiDotsVertical } from 'react-icons/hi';

import { IAgreement, IAgreementInput } from '@/@types/agreementTypes';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Label } from '@/components/ui/label';
import useClickOutside from '@/hooks/useClickOutside';
import { strings } from '@/locales';
import { deleteAgreement, updateAgreement } from '@/services/agreement';

import Options from './Options';
import UpdateAgreementModal from './UpdateAgreementModal';

interface SelectOptionProps {
  id: number;
  isAgreementCell?: boolean;
  dropdownOpenId: number | null;
  setDropdownOpenId: (value: SetStateAction<number | null>) => void;
  setDataList: React.Dispatch<React.SetStateAction<IAgreement[]>>;
  dataList: IAgreement[];
}

export const AgreementSelectOption: React.FC<SelectOptionProps> = ({
  id,
  dropdownOpenId,
  setDropdownOpenId,
  setDataList,
  dataList,
}) => {
  const toggleDropdown = (id: number) => {
    setDropdownOpenId((prevId) => (prevId === id ? null : id));
  };
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IAgreementInput>({
    mode: 'all',
    defaultValues: {
      description:
        dataList.find((data) => data._id === id.toString())?.description || '',
    },
  });
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => {
    if (!showViewModal && !showEditModal && !showConfirmationModal) {
      setDropdownOpenId(null);
    }
  });
  const handleDeleteButton = async () => {
    if (dropdownOpenId) {
      const response = await deleteAgreement(dropdownOpenId?.toString());
      if (response) {
        setDataList(
          dataList.filter((data) => data._id !== dropdownOpenId.toString()),
        );
      }
    }
    setDropdownOpenId(null);
    setShowConfirmationModal(false);
  };
  const handleUpdateButton: SubmitHandler<IAgreementInput> = async (data) => {
    if (dropdownOpenId) {
      const response = await updateAgreement(dropdownOpenId.toString(), data);
      if (response?.data) {
        const cloneDataList = [...dataList];
        const findIndex = cloneDataList.findIndex(
          (val) => val._id === dropdownOpenId?.toString(),
        );
        cloneDataList[findIndex] = response?.data as IAgreement;
        setDataList(cloneDataList);
      }
    }
    handleClose();
  };
  const handleClose = () => {
    setShowEditModal(false);
    setShowConfirmationModal(false);
    setShowViewModal(false);
    setDropdownOpenId(null);
    reset();
  };
  return (
    <div className='relative text-left'>
      <div className='cursor-pointer' onClick={() => toggleDropdown(id)}>
        <HiDotsVertical size={20} />
      </div>
      {dropdownOpenId === id ? (
        <Options
          setShowConfirmationModal={setShowConfirmationModal}
          setShowEditModal={setShowEditModal}
          setShowViewModal={setShowViewModal}
          dropdownRef={dropdownRef}
        />
      ) : null}
      <Modal
        title={strings.agreement.title}
        open={showViewModal}
        setOpen={handleClose}
        hideButtons
      >
        <div>
          <div className='text-white'>
            <div className='flex flex-col gap-y-2 flex-grow'>
              <Label className='text-base mt-2 mb-1'>
                {strings.agreement.title}
              </Label>
              <div className='bg-darkGray border-none focus-visible:ring-0 focus-visible:ring-offset-0 rounded-[14px] p-3 text-[15px] w-full h-60 overflow-auto'>
                {
                  dataList.find(
                    (data: { _id: string }) => data._id === id.toString(),
                  )?.description
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <UpdateAgreementModal
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        handleUpdateButton={handleUpdateButton}
        showEditModal={showEditModal}
        setShowEditModal={handleClose}
      />
      <Modal
        title={strings.agreement.modalTitle}
        btnText={strings.agreement.delete}
        handleBtn={handleDeleteButton}
        open={showConfirmationModal}
        setOpen={handleClose}
      >
        <div>
          <Typography variant='p' className='text-base mt-3 mb-5'>
            {strings.agreement.confirmaton}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};
