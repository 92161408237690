import React, { SetStateAction, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { HiDotsVertical } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { userDataType } from '@/@types';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import useClickOutside from '@/hooks/useClickOutside';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { banUser, sendEmail } from '@/services/users';

import SendEmailModal, { ISendEmailField } from './SendEmailModal';

interface SelectOptionProps {
  id: number;
  dropdownOpenId: number | null;
  setDropdownOpenId: (value: SetStateAction<number | null>) => void;
  dataList: userDataType[];
  setDataList: (value: SetStateAction<userDataType[]>) => void;
}
export const SelectOption: React.FC<SelectOptionProps> = ({
  id,
  dropdownOpenId,
  setDropdownOpenId,
  dataList,
  setDataList,
}) => {
  const toggleDropdown = (id: number) => {
    setDropdownOpenId((prevId) => (prevId === id ? null : id));
  };
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<ISendEmailField>({
    mode: 'all',
    defaultValues: {
      emailText: '',
    },
  });

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => {
    if (!showConfirmationModal && !showSendEmailModalOpen) {
      setDropdownOpenId(null);
    }
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSendEmailModalOpen, setSendEmailModalOpen] = useState(false);

  const handleDeleteButton = async () => {
    setDropdownOpenId(null);
    const response = await banUser({ isBan: true, userID: String(id) });
    if (response.status) {
      setShowConfirmationModal(false);
      setDataList(
        dataList.map((data: userDataType) =>
          data._id === id ? { ...data, isBanned: true } : data,
        ),
      );
    }
  };
  const user = useMemo(
    () => dataList.find((user: userDataType) => user._id === id),
    [dataList, id],
  );
  const handleSendEmailButton: SubmitHandler<ISendEmailField> = async (
    data,
  ) => {
    const payload = {
      email: user?.email || '',
      message: data.emailText,
    };
    const response = await sendEmail(payload);
    if (response) {
      reset();
      setDropdownOpenId(null);
    }
  };
  const handleClose = () => {
    setSendEmailModalOpen(false);
    setShowConfirmationModal(false);
    setDropdownOpenId(null);
    reset();
  };
  const handleNavigateToUserDetail = () => {
    navigate(`${ROUTES.USER_DETAIL}/${id}`, { state: { user } });
  };
  return (
    <div className='relative text-left'>
      <div className='cursor-pointer' onClick={() => toggleDropdown(id)}>
        <HiDotsVertical size={20} className='' />
      </div>
      {dropdownOpenId === id ? (
        <div
          className='bg-blueFantastic absolute right-[-2px] top-7 rounded-xl w-36 px-3 py-1 z-10'
          ref={dropdownRef}
        >
          <div
            onClick={handleNavigateToUserDetail}
            className='py-1 text-white cursor-pointer text-xs'
          >
            {strings.dashboard.tableOptions.view}
          </div>
          <div
            className='py-1 text-white cursor-pointer text-xs'
            onClick={() => setShowConfirmationModal(true)}
          >
            {strings.userDetailScreen.ban}
          </div>
          <div
            className='py-1 text-white cursor-pointer text-xs'
            onClick={() => setSendEmailModalOpen(true)}
          >
            {strings.dashboard.tableOptions.sendEmail}
          </div>
        </div>
      ) : null}
      {/* delete confirmation modal */}
      <Modal
        title={strings.userDetailScreen.deleteModalTitle}
        btnText={strings.userDetailScreen.ban}
        handleBtn={handleDeleteButton}
        open={showConfirmationModal}
        setOpen={handleClose}
      >
        <Typography variant='p' className='text-base mt-3 mb-5'>
          {strings.userDetailScreen.confirmaton}
        </Typography>
      </Modal>
      <SendEmailModal
        control={control}
        errors={errors}
        handleSubmit={handleSubmit}
        handleUpdateButton={handleSendEmailButton}
        showSendEmailModalOpen={showSendEmailModalOpen}
        setShowSendEmailModalOpen={handleClose}
        watch={watch}
      />
    </div>
  );
};
