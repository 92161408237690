import { useEffect } from 'react';
import { GetCity, GetState } from 'react-country-state-city';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import { PersonalInfoCardType, userDataType } from '@/@types';
import { Typography } from '@/components/Typography';
import useDashboardContext from '@/hooks/useDashboard';
import { useGetLocation } from '@/hooks/useLocation';
import { strings } from '@/locales';
import { truncateText } from '@/utils/common';

import EditAddressInfo from './EditAddressInfo';
import EditPersonalInfo from './EditPersonalInfo';
import EditPersonalInfoHeadingAndButton from './EditPersonalInfoHeadingAndButton';
import UserDetailSection from './UserDetailSection';

const PersonalInfoCard: React.FC<PersonalInfoCardType> = ({
  isEdit,
  setIsEdit,
}) => {
  const staticText = strings.userDetailScreen;
  const { location, setLocation } = useGetLocation();
  const { user } = useDashboardContext();
  const countryById = location.countryList.find(
    (country: { name: string }) => country.name === user?.user?.country,
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<userDataType>({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      joinedAt: '',
      status: '',
      country: '',
      state: '',
      city: '',
      zipCode: 0,
    },
  });

  useEffect(() => {
    if (user) {
      setValue('firstName', user?.user?.firstName || '');
      setValue('lastName', user?.user?.lastName || '');
      setValue('email', user?.user?.email || '');
      setValue('role', user?.user?.role || '');
      setValue('joinedAt', dayjs(user?.user?.createdAt).format('YYYY-MMM-DD'));
      setValue('status', user?.user?.status || '');
      setValue('zipCode', user?.user?.zipCode || 0);
    }
  }, [user]);

  const fetchStateAndCity = async () => {
    const states = await GetState(countryById?.id);
    const stateIndex = states.findIndex(
      (state: { name: string }) => state.name === user?.user?.state,
    );
    const cities = await GetCity(countryById?.id, states[stateIndex]?.id);
    const cityIndex = cities.findIndex(
      (city: { name: string }) => city.name === user?.user?.city,
    );
    setLocation((prevLocation) => ({
      ...prevLocation,
      stateList: states,
      stateId: stateIndex,
      cityList: cities,
      cityId: cityIndex,
    }));
    setValue('state', String(stateIndex));
    setValue('city', String(cityIndex));
  };

  useEffect(() => {
    if (countryById) {
      fetchStateAndCity();
    }
  }, [countryById, isEdit]);

  useEffect(() => {
    if (countryById) {
      setValue('country', String(countryById.id - 1));
    }
  }, [countryById, setValue]);

  return (
    <div className='mt-6 w-full border border-borderWhite rounded-xl p-5'>
      <EditPersonalInfoHeadingAndButton
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        handleSubmit={handleSubmit}
      />
      {isEdit ? (
        <EditPersonalInfo control={control} errors={errors} />
      ) : (
        <UserDetailSection
          key={user?.user?._id}
          titleTwo={staticText.address}
          fields={[
            {
              label: staticText.name,
              value: user?.user?.firstName
                ? `${user?.user?.firstName} ${user?.user?.lastName}`
                : 'N/A',
              hasUserImage: user?.user?.image,
            },
            {
              label: staticText.email,
              value: truncateText(user?.user?.email || ''),
            },
            { label: staticText.role, value: user?.user?.role || '' },
            {
              label: staticText.joindAt,
              value: dayjs(user?.user?.createdAt).format('YYYY-MMM-DD') || '',
            },
            { label: staticText.status, value: user?.user?.status || 'N/A' },
          ]}
        />
      )}
      <Typography className='font-semibold text-lg md:text-xl mb-3 mt-10'>
        {staticText.address}
      </Typography>
      {isEdit ? (
        <EditAddressInfo
          control={control}
          errors={errors}
          location={location}
          setLocation={setLocation}
        />
      ) : (
        <UserDetailSection
          fields={[
            { label: staticText.country, value: user?.user?.country || 'N/A' },
            {
              label: staticText.cityOrState,
              value:
                user?.user?.state && user?.user?.city
                  ? `${user?.user.state}/${user?.user.city}`
                  : 'N/A',
            },
            { label: staticText.zipCode, value: user?.user?.zipCode || 0 },
          ]}
          hideEditButton
        />
      )}
    </div>
  );
};

export default PersonalInfoCard;
