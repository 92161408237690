import { Typography } from '@/components/Typography';
import {
  PAYMENT_STATUS,
  TABLE_COLUMN_ENUM,
  USER_TYPE,
} from '@/constants/common';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

const UserDetailSection: React.FC<{
  hideEditButton?: boolean;
  titleTwo?: string;

  fields: { label: string; value: string | number; hasUserImage?: string }[];
}> = ({ fields }) => {
  return (
    <div className='lg:w-10/12 grid lg:grid-cols-4 gap-x-7 gap-y-12'>
      {fields.map(
        (
          field: {
            label: string;
            value: string | number;
            hasUserImage?: string;
          },
          index: number,
        ) => (
          <div key={index}>
            <Typography className='text-megnesium text-base'>
              {field.label}
            </Typography>
            <Typography className='font-medium text-base gap-2  flex mt-1.5'>
              {field.hasUserImage &&
              field.label === strings.userDetailScreen.name ? (
                <div className='flex items-center'>
                  <img
                    className='w-7 h-7 rounded-full'
                    src={field.hasUserImage}
                    alt='user'
                  />
                </div>
              ) : null}
              {(field.label === TABLE_COLUMN_ENUM.STATUS &&
                field.value !== 'N/A') ||
              field.label === TABLE_COLUMN_ENUM.ROLE ? (
                <div
                  className={cn(
                    ' flex items-center py-0.5 capitalize rounded-full justify-center w-24',
                    {
                      'bg-greenishWhite text-charmedGreen':
                        field.value === PAYMENT_STATUS.PAID ||
                        field.value === USER_TYPE.ADMIN,
                      'bg-fallStraw text-therapeuticToucan ':
                        field.value === PAYMENT_STATUS.DRAFT,
                      'bg-darkGray text-white ':
                        field.value === PAYMENT_STATUS.OPEN,
                      'bg-lightSky text-homeWorld ':
                        field.value === PAYMENT_STATUS.ACTIVE ||
                        field.value === USER_TYPE.USER,
                    },
                  )}
                >
                  <div
                    className={cn('h-1.5 w-1.5 rounded-full me-2', {
                      'hidden ': field.label === TABLE_COLUMN_ENUM.ROLE,
                      'bg-charmedGreen ': field.value === PAYMENT_STATUS.PAID,
                      'bg-therapeuticToucan ':
                        field.value === PAYMENT_STATUS.DRAFT,
                      'bg-white ': field.value === PAYMENT_STATUS.OPEN,
                      'bg-homeWorld ': field.value === PAYMENT_STATUS.ACTIVE,
                    })}
                  />
                  {field.value}
                </div>
              ) : (
                field.value
              )}
            </Typography>
          </div>
        ),
      )}
    </div>
  );
};
export default UserDetailSection;
