import toast from 'react-hot-toast';

import { IAgreement, IAgreementInput } from '@/@types/agreementTypes';
import { ResponseType } from '@/@types/authTypes';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from '@/constants/feedback';
import { REST_SUB_URL } from '@/constants/url';

export const getAgreements = async () => {
  const response = await API.Get<ResponseType<IAgreement[]>>(
    `${REST_SUB_URL.GET_AGREEMENTS}`,
  );
  return response?.data;
};

export const createAgreement = async (payload: { description: string }) => {
  const response = await API.Post<IAgreementInput, ResponseType<IAgreement>>(
    REST_SUB_URL.CREATE_AGREEMENT,
    payload,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};

export const updateAgreement = async (
  agreementId: string,
  payload: { description: string },
) => {
  const response = await API.Put<ResponseType<IAgreement>, IAgreementInput>(
    `${REST_SUB_URL.UPDATE_AGREEMENT}/${agreementId}`,
    { ...payload },
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response.data;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};

export const deleteAgreement = async (agreementId: string) => {
  const response = await API.Delete<ResponseType<IAgreement>>(
    `${REST_SUB_URL.DELETE_AGREEMENT}/${agreementId}`,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return true;
  }
  toast.error(response?.message || COMMON_ERROR);
  return false;
};
