import { useState } from 'react';
import toast from 'react-hot-toast';

import { ReviewType } from '@/@types/reviewsType';
import { strings } from '@/locales';
import { getPostedReviews, postReview } from '@/services/reviews';

const useReviews = ({
  postedReviews,
  rowSelectionData,
  setAllReviews,
  setRowSelectionData,
  setShowConfirmationModal,
  setPostedReviews,
}: {
  postedReviews: ReviewType[] | undefined;
  setRowSelectionData: React.Dispatch<
    React.SetStateAction<ReviewType[] | undefined>
  >;
  rowSelectionData: ReviewType[] | undefined;
  setAllReviews: React.Dispatch<React.SetStateAction<ReviewType[] | undefined>>;
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPostedReviews: React.Dispatch<
    React.SetStateAction<ReviewType[] | undefined>
  >;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchPostedReviews = async () => {
    try {
      const responsePostedNews = await getPostedReviews();
      setPostedReviews(responsePostedNews?.data);
    } catch (error) {
      toast.error(strings.review.errorMessage);
    }
  };

  const handlePostReview = async () => {
    setIsLoading(true);
    try {
      // gett posted reviews through api
      const postedNewsData = postedReviews ?? [];
      const postedNewsMap = new Map(
        postedNewsData.map((item: ReviewType) => [item._id, item]),
      );
      let itemsToUpdate = [];
      if (Array.isArray(rowSelectionData) && rowSelectionData.length > 0) {
        // Find items to set isPosted to false if user uncheck the review
        const itemsToUnpost = postedNewsData
          .filter((postedItem: ReviewType) => {
            return !rowSelectionData.some(
              (selectedItem: ReviewType) => selectedItem._id === postedItem._id,
            );
          })
          .map((item) => ({ ...item, isPosted: false }));
        // Find items to set isPosted to true
        const itemsToPost = rowSelectionData
          .filter((selectedItem) => {
            return !postedNewsMap.has(selectedItem._id);
          })
          .map((item) => ({ ...item, isPosted: true }));

        itemsToUpdate = [...itemsToUnpost, ...itemsToPost];
      } else {
        // If no items are selected, set all posted items to unposted
        itemsToUpdate = postedNewsData.map((item) => ({
          ...item,
          isPosted: false,
        }));
      }
      // Send API requests
      const promises = itemsToUpdate.map(async (data) => {
        const payload = { reviewID: data._id, isPost: data.isPosted };
        const response = await postReview(payload);
        if (response.status) {
          setAllReviews((prevReviews) =>
            (prevReviews ?? []).map((review) =>
              review._id === data._id
                ? { ...review, isPosted: data.isPosted }
                : review,
            ),
          );
        }
        return response.status;
      });
      const results = await Promise.all(promises);
      if (results.every((status) => status)) {
        setRowSelectionData([]);
        setShowConfirmationModal(false);
      }
    } catch (error) {
      toast.error(strings.review.errorMessage);
    } finally {
      setIsLoading(false);
      fetchPostedReviews();
    }
  };

  return {
    isLoading,
    handlePostReview,
    fetchPostedReviews,
  };
};

export default useReviews;
