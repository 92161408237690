export const BASE_URL = process.env.REACT_APP_BASE_URL || '';
export const REST_URL = `${BASE_URL}`;

export const CONFIG = {
  X_API_KEY: process.env.X_API_KEY,
};

export const REST_SUB_URL = {
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgot-password',
  OTP: '/auth/verify-otp',
  RESEND_OTP: '/auth/resend-otp',
  RESET_PASSWORD: '/auth/reset-password',
  GET_PROFILE: '/auth/me',
  GET_ALL_REVIEWS: '/review/get-all',
  GET_POSTED_REVIEWS: '/review/posted-reviews',
  POST_REVIEW: '/review/post-review/',
  GET_ALL_USERS: '/auth/get-users',
  GET_ALL_NEWS: '/news/get-news',
  CREATE_NEWS: '/news/create-news',
  UPDATE_NEWS: '/news/update-news',
  DELETE_NEWS: '/news/delete-news',
  POST_NEWS_STATUS: '/news/post-news',
  BAN_USER: '/auth/block-user/',
  UPDATE_USER: '/auth/edit-user',
  SEND_EMAIL: '/auth/send-email',
  GET_USER_DETAIL: '/auth/user-detail',
  GET_AGREEMENTS: '/agreement/get-agreements',
  CREATE_AGREEMENT: '/agreement/create-agreement',
  UPDATE_AGREEMENT: '/agreement/update-agreement',
  DELETE_AGREEMENT: '/agreement/delete-agreement',
  SIGNIN_AS_USER: '/auth/random-user',
  GET_HOURS_LIST: '/finviz-frequency/get',
  POST_HOUR_LIST: '/finviz-frequency/create',
};
