import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { RxCrossCircled } from 'react-icons/rx';

import { HoursPickerProps, IHourList } from '@/@types/finvizTypes';
import Loader from '@/components/Loader';
import withProtectedRoute from '@/components/ProtectedRoute';
import SelectInput from '@/components/SelectInput';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { COMMON_ERROR } from '@/constants/feedback';
import { strings } from '@/locales';
import { getHoursList, postHoursList } from '@/services/finviz';

const HoursPicker: React.FC<HoursPickerProps> = ({ id, value, onChange }) => {
  const USER_TYPE_OPTIONS = Array.from({ length: 24 }, (_, i) => ({
    value: i.toString(),
    label: `${i}:00`,
  }));
  const [selectedHour, setSelectedHour] = useState<string | null>(
    value || null,
  );

  useEffect(() => {
    setSelectedHour(value);
  }, [value]);
  const handleHourChange = (hour: string) => {
    setSelectedHour(hour);
    onChange(id, hour);
  };
  return (
    <SelectInput
      placeholder={strings.finvizScreen.selectAnHour}
      value={selectedHour || ''}
      options={USER_TYPE_OPTIONS}
      onChange={handleHourChange}
      inputClassName='focus:ring-0'
    />
  );
};

const Finviz = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedHourList, setSelectedHourList] = useState<IHourList[]>([
    { hour_: '', id: 0 },
  ]);

  const addInput = () => {
    if (selectedHourList.length < 5) {
      setSelectedHourList((prevHistory) => [
        ...prevHistory,
        { hour_: '', id: Math.random() * 10 },
      ]);
    }
  };

  const fetchHoursList = async () => {
    try {
      const response = await getHoursList();
      if (response?.data.length) {
        setSelectedHourList(
          response.data.map((item: IHourList) => ({
            id: item.id,
            hour_: item.hour_.split(':')[0] ?? '',
          })),
        );
      } else {
        setSelectedHourList([{ hour_: '', id: 0 }]);
      }
    } catch (error) {
      toast.error(COMMON_ERROR);
    }
  };

  useEffect(() => {
    fetchHoursList();
  }, []);

  const removeInput = (id: number) => {
    const updatedInputs = selectedHourList.filter(
      (entry: IHourList) => entry.id !== id,
    );
    setSelectedHourList(updatedInputs);
  };

  const isLastLastEntryFilled = () => {
    const lastTravelEntry = selectedHourList[selectedHourList.length - 1];
    return lastTravelEntry?.hour_;
  };

  const handleHourChange = (id: number, hour: string) => {
    setSelectedHourList((prevHistory) =>
      prevHistory.map((entry: IHourList) =>
        entry?.id === id ? { ...entry, hour_: hour } : entry,
      ),
    );
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await postHoursList(selectedHourList);
    setIsLoading(false);
    if (!response) return;
  };

  return (
    <div className='flex flex-col items-center py-7'>
      <Typography variant='subheading'>{strings.finvizScreen.title}</Typography>
      <div className='w-full md:w-1/2 lg:w-1/3 mt-5'>
        <label htmlFor='hours'>{strings.finvizScreen.selectAnHour}</label>
        <div className='mt-2'>
          {selectedHourList.map((history: IHourList) => (
            <div
              key={history.id}
              className='flex mb-2 rounded-xl items-center gap-2 bg-transparentBlack pr-2'
            >
              <div className='flex flex-col w-full md:flex-row gap-2'>
                <div className='w-full'>
                  <HoursPicker
                    id={history.id}
                    value={history.hour_}
                    onChange={handleHourChange}
                  />
                </div>
              </div>
              <div className='flex justify-center align-middle items-center'>
                <Button
                  onClick={() => removeInput(history?.id)}
                  className='cursor-pointer w-auto bg-transparent hover:bg-tranparent p-0 h-auto'
                  disabled={selectedHourList.length === 1}
                >
                  <RxCrossCircled size={20} color='red' />
                </Button>
              </div>
            </div>
          ))}
          <div className='flex justify-center mt-3'>
            <Button
              onClick={addInput}
              className='cursor-pointer w-auto bg-transparent hover:bg-tranparent p-0 h-auto'
              disabled={
                !isLastLastEntryFilled() || selectedHourList.length >= 5
              }
            >
              <HiOutlinePlusCircle size={25} color='green' />
            </Button>
          </div>
        </div>
        <Button
          variant='gradient'
          className='my-4 text-lg py-0 rounded-xl w-full'
          onClick={handleSubmit}
        >
          {isLoading ? (
            <Loader loading={isLoading} />
          ) : (
            strings.finvizScreen.submit
          )}
        </Button>
      </div>
    </div>
  );
};

export default withProtectedRoute(Finviz);
