import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuthContext from '@/hooks/useAuth';
import { ROUTES } from '@/routes';

import { getAuthToken } from 'src/utils/common';

const withUnauthenticatedRoute = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const UnauthenticatedRoute: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const token = getAuthToken();
    const { auth } = useAuthContext();

    useEffect(() => {
      if (token && auth?.user) {
        navigate(ROUTES.DASHBOARD);
      }
    }, [token, auth]);

    return <WrappedComponent {...props} />;
  };

  return UnauthenticatedRoute;
};

export default withUnauthenticatedRoute;
