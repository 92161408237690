export const ROUTES = {
  HOME: '/',
  USER_DETAIL: '/user-detail',
  STOCK: '/stock',
  PROFILE: '/profile',
  LOGIN: '/login',
  OTP: '/otp',
  FORGOT_PASSWORD: '/forgot-password',
  SET_NEW_PASSWORD: '/new-password',
  DASHBOARD: '/dashboard',
  NEWS_LIST: '/news-list',
  NEWS_DETAIL: '/news-detail',
  REVIEWS: '/reviews',
  AGREEMENT: '/agreement',
  ADD_NEWS_AND_VIDEOS: '/add-news-and-videos',
  SIGN_IN_AS_USER: '/signin-as-user',
  FINVIZ: '/finviz',
};
export const protectedRoutes = [
  ROUTES.DASHBOARD,
  ROUTES.STOCK,
  '/user-detail/:userId',
  ROUTES.NEWS_LIST,
  ROUTES.ADD_NEWS_AND_VIDEOS,
  ROUTES.REVIEWS,
  ROUTES.AGREEMENT,
  ROUTES.NEWS_DETAIL,
  `${ROUTES.USER_DETAIL}/:newsId`,
  ROUTES.USER_DETAIL,
  ROUTES.SIGN_IN_AS_USER,
  ROUTES.FINVIZ,
];
