/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { IoArrowBack } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import PostIcon from '@/assets/svgs/postIcon';
import CheckboxInput from '@/components/Checkbox';
import Loader from '@/components/Loader';
import withProtectedRoute from '@/components/ProtectedRoute';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { INPUT_ERRORS } from '@/constants/feedback';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { postNews, updateNews } from '@/services/news';
import { INewsData } from '@/services/types';

import DragAndDropBox from './DragAndDropBox';

const AddNewsOrVideos = () => {
  const staticText = strings.addNews;

  const location = useLocation();
  const { state } = location;
  const dataForEdit = state?.dataForEdit;
  const [isEditId, setIsEditId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [mediaError, setMediaError] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<INewsData>({
    mode: 'all',
    defaultValues: {
      file: dataForEdit?.file,
      description: dataForEdit?.description || '',
      title: dataForEdit?.title,
      isPosted: dataForEdit?.isPosted || false,
    },
  });
  const [mediaFile, setMediaFile] = useState<File | null>(null);
  useEffect(() => {
    if (dataForEdit) {
      setMediaFile(dataForEdit.file);
      setIsEditId(dataForEdit._id);
    }
  }, [dataForEdit]);
  useEffect(() => {
    if (mediaFile) {
      setMediaError('');
    }
  }, [mediaFile]);

  const handleSendButton: SubmitHandler<INewsData> = async (data) => {
    if (!mediaFile) {
      setMediaError(INPUT_ERRORS.FILE.required);
      return;
    }
    setMediaError('');
    setIsLoading(true);
    const payload = {
      ...data,
      file: mediaFile,
      isPosted: Boolean(data.isPosted),
    };
    const response = await postNews(payload);
    if (response) {
      reset();
      navigate(ROUTES.NEWS_LIST);
    }
    setIsLoading(false);
  };
  const handleUpdateButton: SubmitHandler<INewsData> = async (data) => {
    if (!mediaFile) {
      setMediaError('Media file is required');
      return;
    }
    setMediaError('');
    setIsLoading(true);
    const payload = {
      ...data,
      file: mediaFile,
      isPosted: Boolean(data.isPosted),
    };
    const response = await updateNews(isEditId, payload);
    if (response.status) {
      reset();
      navigate(ROUTES.NEWS_LIST);
    }
    setIsLoading(false);
  };
  return (
    <div className='my-6 ml-[16px] mr-6'>
      <div className='flex justify-between flex-col md:flex-row gap-3 md:gap-0 mx-1 md:items-center'>
        <div className='flex'>
          <Link to={ROUTES.NEWS_LIST}>
            <div className='size-8 bg-blueFantastic rounded-[6px] flex justify-center items-center mr-2 cursor-pointer'>
              <IoArrowBack size={20} />
            </div>
          </Link>
          <Typography variant='p' className='font-semibold md:text-xl mx-1'>
            {staticText.heading}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant={'primary'}
            onClick={
              isEditId
                ? handleSubmit(handleUpdateButton)
                : handleSubmit(handleSendButton)
            }
            className='rounded-full p-3 sm:p-5 font-medium text-base'
          >
            {isLoading ? (
              <Loader loading={isLoading} />
            ) : (
              <>
                <PostIcon className='mr-2 sm:mr-4' />
                {isEditId ? staticText.updateNews : staticText.buttonText}
              </>
            )}
          </Button>
        </div>
      </div>
      <div className='rounded-[14px] bg-primaryBackground border border-borderWhite p-[20px] mt-[33px]'>
        <Typography variant='p' className='font-semibold text-xl mx-1'>
          {staticText.heading}
        </Typography>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 mt-7'>
          <DragAndDropBox
            mediaFile={mediaFile}
            setMediaFile={setMediaFile}
            mediaError={mediaError}
          />

          <div className='space-y-3 flex flex-col'>
            <div className='flex flex-col gap-y-2'>
              <Label className='text-base'>{staticText.title}*</Label>
              <Controller
                control={control}
                name='title'
                rules={{
                  required: INPUT_ERRORS.TITLE.required,
                }}
                render={({ field: { onChange, value, name } }) => (
                  <Input
                    placeholder={staticText.titlePlaceHolder}
                    value={value}
                    name={name}
                    onChange={onChange}
                    maxLength={100}
                  />
                )}
              />
              {errors.title ? (
                <Typography variant='p' className='text-red-500'>
                  {errors.title.message}
                </Typography>
              ) : null}
            </div>
            <div className='flex flex-col gap-y-2 flex-grow'>
              <Label className='text-base'>{staticText.description}</Label>
              <Controller
                control={control}
                name='description'
                render={({ field: { onChange, value, name } }) => (
                  <Textarea
                    className='bg-darkGray border-none rounded-[14px] p-3 text-[15px] w-full h-full'
                    placeholder={staticText.descriptionPlaceHolder}
                    value={value}
                    name={name}
                    onChange={onChange}
                  />
                )}
              />
              <div className='flex items-center space-x-2 mt-2'>
                <Controller
                  control={control}
                  name='isPosted'
                  render={({ field: { onChange, value } }) => (
                    <CheckboxInput
                      label='select'
                      checked={value}
                      setChecked={(checked) => onChange(checked)}
                    />
                  )}
                />
                <div className='grid gap-1.5 leading-none'>
                  <label htmlFor='terms1' className='text-sm '>
                    {staticText.postToDashboard}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withProtectedRoute(AddNewsOrVideos);
