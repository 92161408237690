import React from 'react';
import { GetState } from 'react-country-state-city';
import { FieldErrors } from 'react-hook-form';

import { strings } from '@/locales';

import AddressSelect, { CountryType, StatesType } from './AddressSelect';

type CountryFieldsProps = {
  errors: FieldErrors;
  value: string | undefined;
  onChange: () => void;
  countryId: number;
  setCountryId: (countryId: number) => void;
  countryList: CountryType[];
  setStateList: (states: StatesType[]) => void;
  isRequired?: boolean;
};

const CountrySelect: React.FC<CountryFieldsProps> = ({
  errors,
  value,
  onChange,
  setCountryId,
  countryList,
  setStateList,
  isRequired = false,
}) => {
  const handleCountryChange = (fieldOnChange: (value: string) => void) => {
    return (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedCountry = countryList[parseInt(e.target.value)];
      if (selectedCountry) {
        setCountryId(selectedCountry.id);
        GetState(selectedCountry.id).then((states: StatesType[]) => {
          setStateList(states);
        });
      }
      fieldOnChange(e.target.value);
    };
  };
  return (
    <AddressSelect
      isRequired={isRequired}
      labelCalssName='text-base'
      onChange={handleCountryChange(onChange)}
      errors={errors}
      dataList={countryList}
      value={value}
      title={strings.userDetailScreen.country}
      placeholder={strings.userDetailScreen.select}
    />
  );
};

export default CountrySelect;
