import { Control, Controller, FieldErrors } from 'react-hook-form';

import { userDataType } from '@/@types';
import CalendarSelect from '@/components/CalendarInput';
import FormField from '@/components/FormField';
import SelectInput from '@/components/SelectInput';
import { PAYMENT_STATUS_OPTIONS, USER_TYPE_OPTIONS } from '@/constants/common';
import { INPUT_ERRORS } from '@/constants/feedback';
import { strings } from '@/locales';

const EditPersonalInfo: React.FC<{
  control: Control<userDataType>;
  errors: FieldErrors<userDataType>;
}> = ({ control, errors }) => {
  const staticText = strings.userDetailScreen;
  return (
    <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7'>
      <Controller
        control={control}
        name='firstName'
        rules={{
          required: INPUT_ERRORS.FIRST_NAME.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={staticText.firstName}
            placeholder={staticText.firstNamePlace}
            name={name}
            labelContainerCalssName='mt-0'
            labelCalssName='text-base'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='lastName'
        rules={{
          required: INPUT_ERRORS.LAST_NAME.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={staticText.lastName}
            placeholder={staticText.lastNamePlace}
            name={name}
            value={value}
            labelCalssName='text-base'
            labelContainerCalssName='mt-0'
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='email'
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={staticText.email}
            placeholder={staticText.email}
            name={name}
            labelContainerCalssName='mt-0'
            value={value}
            disabled
            onChange={onChange}
            labelCalssName='text-base'
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='role'
        rules={{
          required: INPUT_ERRORS.ROLE.required,
        }}
        render={({ field: { value, onChange } }) => (
          <SelectInput
            title={staticText.role}
            placeholder={staticText.select}
            value={value || ''}
            errors={errors}
            options={USER_TYPE_OPTIONS}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={control}
        name='joinedAt'
        render={({ field: { onChange, value } }) => (
          <CalendarSelect
            placeholder={staticText.select}
            value={value ? new Date(value) : undefined}
            onChange={onChange}
            label={staticText.joindAt}
          />
        )}
      />
      <Controller
        control={control}
        name='status'
        render={({ field: { value, onChange } }) => (
          <SelectInput
            title={staticText.status}
            placeholder={staticText.select}
            value={value}
            errors={errors}
            options={PAYMENT_STATUS_OPTIONS}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
};
export default EditPersonalInfo;
