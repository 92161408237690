import toast from 'react-hot-toast';

import {
  EmailPayloadType,
  IUserDetail,
  sendEmailApiResponse,
  userDataType,
} from '@/@types';
import { UserApiRessponse } from '@/@types/authTypes';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from '@/constants/feedback';
import { REST_SUB_URL } from '@/constants/url';

import { banUserApiResponse } from './types';
type ResponseType<T> = {
  statusCode: number;
  data: T;
  message: string;
  status: boolean;
};
export const getAllUsers = async (pageNumber: number) => {
  const response = await API.Get<UserApiRessponse>(
    `${REST_SUB_URL.GET_ALL_USERS}/${pageNumber}`,
  );
  return response?.data;
};
export const banUser = async (payload: { isBan: boolean; userID: string }) => {
  const response = await API.Post<
    { isBan: boolean; userID: string },
    banUserApiResponse
  >(REST_SUB_URL.BAN_USER, payload);
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
export const updateUser = async (userId: string, payload: userDataType) => {
  const response = await API.Put<ResponseType<userDataType>, userDataType>(
    `${REST_SUB_URL.UPDATE_USER}/${userId}`,
    payload,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
export const sendEmail = async (payload: EmailPayloadType) => {
  const response = await API.Post<EmailPayloadType, sendEmailApiResponse>(
    REST_SUB_URL.SEND_EMAIL,
    payload,
  );
  if (response.status) {
    toast.success(response?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
export const getUserDetail = async (userId: string) => {
  const response = await API.Get<ResponseType<IUserDetail>>(
    `${REST_SUB_URL.GET_USER_DETAIL}/${userId}`,
  );
  return response?.data;
};
