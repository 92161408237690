import React, { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { cn } from 'src/lib/utils';

import { Input } from './ui/input';
import { Typography } from './Typography';

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title: string;
  placeholder: string;
  labelCalssName?: string;
  name: string;
  value: string | number | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: FieldErrors;
  isRequired?: boolean;
  labelContainerCalssName?: string;
}

const FormField: React.FC<FormFieldProps> = ({
  title,
  placeholder,
  name,
  value,
  labelCalssName,
  onChange,
  errors,
  isRequired,
  type,
  labelContainerCalssName,
  ...props
}) => {
  const [hidePassword, setHidePassword] = useState(type === 'password');
  const togglePasswordVisibility = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <>
      <label
        className={cn('flex flex-col my-2 relative', labelContainerCalssName)}
      >
        <Typography className={cn('my-2 flex', labelCalssName)}>
          {title}
          {isRequired ? (
            <span className='text-redColor text-xl ml-1'>*</span>
          ) : null}
        </Typography>
        <Input
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={hidePassword ? 'password' : 'text'}
          {...props}
        />
        {type === 'password' ? (
          <button
            onClick={togglePasswordVisibility}
            className='absolute right-4 top-14'
            type='button'
            aria-label={hidePassword ? 'Hide password' : 'Show password'}
          >
            {hidePassword ? (
              <FaEye fill='white' size={17} />
            ) : (
              <FaEyeSlash fill='white' size={17} />
            )}
          </button>
        ) : null}
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor mt-1 md:mt-2'>{message}</p>
          )}
        />
      </label>
    </>
  );
};

export default FormField;
