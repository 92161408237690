import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
} from 'react-hook-form';

import FormField from 'src/components/FormField';
import Loader from 'src/components/Loader';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { IFRAME_EMAIL_SCREEN } from 'src/constants/auth';
import { INPUT_ERRORS } from 'src/constants/feedback';
import { EmailRegex } from 'src/utils/common';

import { IEmailField } from '.';

interface IForgotPasswordField {
  isLoading: boolean;
  control: Control<IEmailField>;
  handleSubmit: UseFormHandleSubmit<IEmailField, undefined>;
  errors: FieldErrors<IEmailField>;
  handleEmailSubmit: SubmitHandler<IEmailField>;
}

const EmailForm: React.FC<IForgotPasswordField> = ({
  isLoading,
  control,
  handleEmailSubmit,
  errors,
  handleSubmit,
}) => {
  return (
    <div className='bg-nightSky md:px-10 md:py-6 mt-20 mb-40 lg:px-20 p-6 lg:py-8 flex flex-col mx-auto w-11/12 md:w-7/12 lg:w-5/12 rounded-xl'>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {IFRAME_EMAIL_SCREEN.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto  text-base text-center'>
        {IFRAME_EMAIL_SCREEN.DESC}
      </Typography>
      <Controller
        control={control}
        name='email'
        rules={{
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={IFRAME_EMAIL_SCREEN.EMAIL.title}
            placeholder={IFRAME_EMAIL_SCREEN.EMAIL.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Button
        variant='gradient'
        className='my-4 text-lg'
        onClick={handleSubmit(handleEmailSubmit)}
      >
        {isLoading ? (
          <Loader loading={isLoading} />
        ) : (
          IFRAME_EMAIL_SCREEN.SUBMIT
        )}
      </Button>
    </div>
  );
};

export default EmailForm;
