import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { IAgreement, IAgreementInput } from '@/@types/agreementTypes';
import PostIcon from '@/assets/svgs/postIcon';
import FilterTable from '@/components/FilterTable';
import { getAgreementColumns } from '@/components/FilterTable/Columns';
import Loader from '@/components/Loader';
import withProtectedRoute from '@/components/ProtectedRoute';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { strings } from '@/locales';
import { createAgreement, getAgreements } from '@/services/agreement';

import AddAgreementModal from './AddAgreementModal';

const Agreement = () => {
  const [dropdownOpenId, setDropdownOpenId] = useState<number | null>(null);
  const [dataList, setDataList] = useState<IAgreement[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IAgreementInput>({
    mode: 'all',
    defaultValues: {
      description: '',
    },
  });
  const columns = getAgreementColumns(
    dropdownOpenId,
    setDropdownOpenId,
    setDataList,
    dataList ?? [],
  );
  const handleAddButton: SubmitHandler<IAgreementInput> = async (data) => {
    setIsLoading(true);
    const response = await createAgreement(data);
    if (response?.data) {
      setDataList((prev) => [response?.data, ...prev] as IAgreement[]);
    }
    setIsLoading(false);
    setShowAddModal(false);
  };
  const handleClose = () => {
    setShowAddModal(false);
    reset();
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchAgreements = async () => {
      try {
        const response = await getAgreements();
        if (response?.data) {
          setDataList(response?.data);
        }
        setIsLoading(false);
      } catch (error) {
        toast.error(strings.review.errorMessage);
      }
    };
    fetchAgreements();
  }, []);
  return (
    <>
      <div className='my-6 ml-[16px] mr-6'>
        <div className='flex justify-between mx-1 items-center'>
          <Typography variant='p' className='font-semibold text-xl'>
            {strings.agreement.title}
          </Typography>
          <div className='flex gap-3'>
            <Button
              variant={'primary'}
              onClick={() => setShowAddModal(true)}
              className='rounded-full p-3 sm:p-5 font-medium text-base'
            >
              {isLoading ? (
                <Loader loading={isLoading} />
              ) : (
                <>
                  <PostIcon className='mr-2 sm:mr-4' />
                  {strings.agreement.buttonText}
                </>
              )}
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div className='flex items-center justify-center h-40'>
            <Loader />
          </div>
        ) : (
          <div className='rounded-[14px] mt-[33px]'>
            <FilterTable columns={columns} data={dataList} />
          </div>
        )}
      </div>
      <AddAgreementModal
        showAddModal={showAddModal}
        control={control}
        handleAddButton={handleAddButton}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
};

export default withProtectedRoute(Agreement);
