import React from 'react';

import { IUserDetail, userDataType } from '@/@types';

export const DashboardContext = React.createContext<{
  dataList: userDataType[];
  setDataList: React.Dispatch<React.SetStateAction<userDataType[]>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number | undefined;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUserDetail | undefined;
  setUser: React.Dispatch<React.SetStateAction<IUserDetail | undefined>>;
}>({
  dataList: [],
  setDataList: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  totalPages: undefined,
  isLoading: false,
  setIsLoading: () => {},
  user: undefined,
  setUser: () => {},
});
