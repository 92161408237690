import * as React from 'react';
import { SVGProps } from 'react';
const PostIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={15}
    viewBox='0 0 18 15'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M4.45911 9.98372H5.46778C5.56915 9.98372 5.66359 9.96617 5.75111 9.93106C5.83926 9.89595 5.92426 9.83733 6.00611 9.7552L10.7548 5.0276C10.836 4.93481 10.8968 4.83763 10.9371 4.73606C10.9774 4.63449 10.9978 4.53355 10.9984 4.43324C10.9991 4.33293 10.9777 4.23355 10.9342 4.13512C10.892 4.03731 10.8322 3.9417 10.7548 3.84829L9.88589 2.95487C9.80529 2.87399 9.71179 2.81349 9.60539 2.77336C9.49835 2.73324 9.39289 2.71317 9.289 2.71317C9.18826 2.71317 9.08689 2.73324 8.98489 2.77336C8.88289 2.81349 8.78498 2.87399 8.69117 2.95487L3.9255 7.6834C3.84365 7.76554 3.78509 7.85018 3.74983 7.93732C3.71457 8.0251 3.69694 8.11977 3.69694 8.22134V9.22479C3.69694 9.44297 3.76935 9.62416 3.91417 9.76836C4.05898 9.91256 4.24063 9.98372 4.45911 9.98372ZM4.53183 9.15049V8.25707L7.78828 5.01443L8.22461 5.47337L8.66755 5.92572L5.42906 9.15049H4.53183ZM8.22461 5.47337L8.66755 5.92572L7.78922 5.01349L8.22461 5.47337ZM8.81961 9.98278H13.8318C13.9659 9.98278 14.078 9.93764 14.168 9.84736C14.2587 9.7577 14.304 9.6461 14.304 9.51256C14.304 9.37839 14.2587 9.26648 14.168 9.17683C14.078 9.08717 13.9656 9.04234 13.8308 9.04234H9.765L8.81961 9.98278ZM3.407 13.1662L1.79767 14.7687C1.55904 15.0063 1.28326 15.0609 0.970333 14.9323C0.657407 14.8038 0.50063 14.5703 0.5 14.2317V1.51975C0.5 1.08652 0.645759 0.72508 0.937278 0.435424C1.2288 0.145768 1.59178 0.000626961 2.02622 0H15.9747C16.4092 0 16.7721 0.145141 17.0637 0.435424C17.3552 0.725707 17.5006 1.08715 17.5 1.51975V11.6474C17.5 12.08 17.3546 12.4414 17.0637 12.7317C16.7728 13.022 16.4098 13.1668 15.9747 13.1662H3.407ZM3.00372 12.2257H15.9757C16.1205 12.2257 16.2536 12.1655 16.3752 12.0452C16.4967 11.9248 16.5571 11.7922 16.5565 11.6474V1.51881C16.5565 1.37461 16.4961 1.24201 16.3752 1.12101C16.2543 1 16.1208 0.939814 15.9747 0.940441H2.02528C1.88046 0.940441 1.7473 1.00063 1.62578 1.12101C1.50426 1.24138 1.44381 1.37398 1.44444 1.51881V13.7728L3.00372 12.2257Z'
      fill='white'
    />
  </svg>
);
export default PostIcon;
