import * as React from 'react';
import { SelectSingleEventHandler } from 'react-day-picker';
import { FaChevronDown } from 'react-icons/fa';
import dayjs from 'dayjs';

import { Button } from 'src/components/ui/button';
import { Calendar } from 'src/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';

import { Typography } from './Typography';

interface IDateInputItem {
  placeholder?: string;
  label?: string;
  isDisabled?: boolean;
  value: Date | undefined;
  onChange: (value: Date) => void;
}

const CalendarSelect: React.FC<IDateInputItem> = ({
  placeholder,
  label,
  value,
  isDisabled,
  onChange,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

  const handleSelect: SelectSingleEventHandler = (newDate) => {
    setIsCalendarOpen(false);
    if (newDate && !isNaN(newDate.getTime())) {
      onChange(newDate);
    }
  };

  const formattedDate = value ? dayjs(value).format('YYYY-MM-DD') : '';

  return (
    <div>
      <Typography className='text-white mt-2 mb-2 text-base'>
        {label}
      </Typography>
      <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
        <PopoverTrigger asChild>
          <Button
            className={cn(
              'w-full h-10 rounded-xl justify-between py-0 hover:text-white text-left font-normal focus:ring-2 focus:ring-white hover:bg-darkGray bg-darkGray',
              !value && 'text-muted-foreground',
            )}
            disabled={isDisabled}
          >
            {formattedDate || <span>{placeholder}</span>}
            <FaChevronDown />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0 bg-darkGray border-0 rounded-xl'>
          <Calendar
            mode='single'
            selected={value}
            onSelect={handleSelect}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default CalendarSelect;
