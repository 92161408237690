import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import FormField from '@/components/FormField';
import withUnauthenticatedRoute from '@/components/PublicRoutes';
import { ROUTES } from '@/routes';
import { forgotPassword } from '@/services/auth';
import { IForgotPasswordField } from '@/services/types';

import Loader from 'src/components/Loader';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { FORGOT_PASSWORD, LOGIN } from 'src/constants/auth';
import { INPUT_ERRORS } from 'src/constants/feedback';
import { EmailRegex } from 'src/utils/common';

import AuthWrapper from '../components/AuthWrapper';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPasswordField>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const handleForgotPassword: SubmitHandler<IForgotPasswordField> = async (
    data,
  ) => {
    setIsLoading(true);
    const response = await forgotPassword(data);
    setIsLoading(false);
    if (!response) return;
    navigate(ROUTES.OTP, { state: { email: data.email, isForgot: true } });
  };

  return (
    <AuthWrapper>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {FORGOT_PASSWORD.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto text-base text-center'>
        {FORGOT_PASSWORD.DESC}
      </Typography>
      <Controller
        control={control}
        name='email'
        rules={{
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={LOGIN.EMAIL.title}
            placeholder={LOGIN.EMAIL.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Button
        variant='gradient'
        className='my-4 text-lg'
        onClick={handleSubmit(handleForgotPassword)}
      >
        {isLoading ? <Loader loading={isLoading} /> : FORGOT_PASSWORD.SUBMIT}
      </Button>
      <Typography variant='p' className='mx-auto capitalize'>
        {FORGOT_PASSWORD.BACK_TO}{' '}
        <Link to={ROUTES.HOME}>
          <span className='text-skyBlue underline'>
            {FORGOT_PASSWORD.LOGIN}
          </span>
        </Link>
      </Typography>
    </AuthWrapper>
  );
};

export default withUnauthenticatedRoute(ForgotPassword);
