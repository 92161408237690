import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { GoDot } from 'react-icons/go';

import { CARD_TYPE, PAYMENT_STATUS } from '@/constants/common';

import cardImage from 'src/assets/svgs/card.svg';
import visaCardImage from 'src/assets/svgs/visa-card.svg';
import { Typography } from 'src/components/Typography';
import { cn } from 'src/lib/utils';

export const Checkbox = ({
  className = '',
  checked,
  setChecked,
}: {
  checked: boolean;
  setChecked: (value: boolean) => void;
} & React.HTMLProps<HTMLDivElement>) => {
  const handleClick = () => {
    setChecked(!checked);
  };
  return (
    <div
      onClick={handleClick}
      className={cn(className, 'cursor-pointer flex items-center')}
    >
      <span className='w-4'>{checked ? <FaCheck size={12} /> : <GoDot />}</span>
      <Typography className='p-0 text-sm m-0'>01</Typography>
    </div>
  );
};

export const Status: React.FC<{ value: PAYMENT_STATUS }> = (value) => (
  <div
    className={cn(
      ' flex items-center py-0.5 capitalize rounded-full justify-center w-24',
      {
        'bg-greenishWhite text-charmedGreen':
          value.value === PAYMENT_STATUS.PAID,
        'bg-fallStraw text-therapeuticToucan ':
          value.value === PAYMENT_STATUS.DRAFT,
        'bg-darkGray text-white ':
          value.value === PAYMENT_STATUS.OPEN ||
          value.value === PAYMENT_STATUS.TRIALING,
        'bg-lightSky text-homeWorld ': value.value === PAYMENT_STATUS.ACTIVE,
      },
    )}
  >
    <div
      className={cn('h-1.5 w-1.5 rounded-full me-2', {
        'bg-charmedGreen ': value.value === PAYMENT_STATUS.PAID,
        'bg-therapeuticToucan ': value.value === PAYMENT_STATUS.DRAFT,
        'bg-white ':
          value.value === PAYMENT_STATUS.OPEN ||
          value.value === PAYMENT_STATUS.TRIALING,
        'bg-homeWorld ': value.value === PAYMENT_STATUS.ACTIVE,
      })}
    />
    {value.value}
  </div>
);
export const CardColumn: React.FC<{
  cardType: CARD_TYPE;
  cardNumber: (arg0: string, last4: unknown) => unknown;
}> = (cardData) => (
  <div className='flex py-2 items-center px-6 whitespace-nowrap mt-1'>
    {cardData.cardType === CARD_TYPE.VISA ? (
      <img className='w-7 h-7' src={visaCardImage} alt={'card-image'} />
    ) : null}
    {cardData.cardType === CARD_TYPE.MASTER ? (
      <img
        className='w-7 h-7 rounded-full'
        src={cardImage}
        alt={'card-image'}
      />
    ) : null}
    <div className='ps-3'>
      <div>{`*******${cardData.cardNumber}`}</div>
    </div>
  </div>
);
