import toast from 'react-hot-toast';

import { ResponseType } from '@/@types/authTypes';
import { IReviewResponse, ReviewType } from '@/@types/reviewsType';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from '@/constants/feedback';
import { REST_SUB_URL } from '@/constants/url';

export const getAllReviews = async (pageNumber: number) => {
  const response = await API.Get<ResponseType<IReviewResponse>>(
    `${REST_SUB_URL.GET_ALL_REVIEWS}/${pageNumber}`,
  );
  return response?.data;
};
export const getPostedReviews = async () => {
  const response = await API.Get<ResponseType<ReviewType[]>>(
    `${REST_SUB_URL.GET_POSTED_REVIEWS}`,
  );
  return response?.data;
};

export const postReview = async (payload: {
  isPost: boolean;
  reviewID: string;
}) => {
  const response = await API.Post<
    {
      isPost: boolean;
      reviewID: string;
    },
    IReviewResponse
  >(REST_SUB_URL.POST_REVIEW, payload);
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
