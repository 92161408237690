import React from 'react';

import { loggedInUserType } from '@/@types/authTypes';

import { AuthContext } from './context';

type PROPS = {
  children:
    | null
    | boolean
    | undefined
    | React.ReactChild
    | React.ReactPortal
    | React.ReactFragment;
};

const AuthProvider: React.FC<PROPS> = ({ children }) => {
  const [auth, setAuth] = React.useState<loggedInUserType | null>(null);

  const contextValue = {
    auth,
    setAuth,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export { AuthContext };
