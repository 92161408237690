/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { ImCancelCircle } from 'react-icons/im';
import { IoArrowBack } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { NewsType } from '@/@types/newsType';
import PostIcon from '@/assets/svgs/postIcon';
import Loader from '@/components/Loader';
import withProtectedRoute from '@/components/ProtectedRoute';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { imageExtensions } from '@/constants/common';
import { COMMON_ERROR } from '@/constants/feedback';
import { NEWS_DETAIL } from '@/constants/newsAndVideos';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { getAllNews, updateNewsStatus } from '@/services/news';

const NewsDetail = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { newsId } = useParams<{ newsId: string }>();
  const [news, setNews] = React.useState<NewsType | null>(null);
  const [isImage, setIsImage] = React.useState(false);
  const [isVideo, setIsVideo] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchNewsDetail = async () => {
      try {
        const response = await getAllNews();
        setNews(
          response?.data.find((item: NewsType) => item._id === newsId) || null,
        );
        setIsLoading(false);
      } catch (error) {
        toast.error(COMMON_ERROR);
      }
    };
    fetchNewsDetail();
  }, []);

  useEffect(() => {
    const fileExtension = news?.file.split('.').pop() || '';
    if (fileExtension === 'mp4') {
      setIsVideo(true);
    } else if (imageExtensions.includes(fileExtension)) {
      setIsImage(true);
    }
  }, [news?.file]);

  const handlePostNews = async () => {
    setIsLoading(true);
    const newStatus = !news?.isPosted;
    const response = await updateNewsStatus(newsId as string, newStatus);
    if (response?.status) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      setNews((prev) => ({ ...prev, isPosted: newStatus } as NewsType));
    }
  };

  return (
    <div className='my-6 ml-[16px] mr-6'>
      <div className='flex justify-between mx-1 flex-col md:flex-row gap-3 md:gap-0 md:items-center'>
        <div className='flex'>
          <Link to={ROUTES.NEWS_LIST}>
            <div className='size-8 bg-blueFantastic rounded-[6px] flex justify-center items-center mr-2 cursor-pointer'>
              <IoArrowBack size={20} />
            </div>
          </Link>
          <Typography variant='p' className='font-semibold md:text-xl mx-1'>
            {news?.title}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            onClick={handlePostNews}
            variant={'primary'}
            className='rounded-full p-3 sm:p-5 font-medium text-base'
          >
            {news?.isPosted ? (
              <>
                <ImCancelCircle className='mr-2 sm:mr-4' />
                {NEWS_DETAIL.unpostedButtonText}
              </>
            ) : isLoading ? (
              <Loader loading={isLoading} />
            ) : (
              <>
                <PostIcon className='mr-2 sm:mr-4' />
                {NEWS_DETAIL.buttonText}
              </>
            )}
          </Button>
        </div>
      </div>
      <div className='rounded-[14px] bg-primaryBackground border border-borderWhite grid grid-cols-1 lg:grid-cols-2 gap-[20px] p-[20px] mt-[33px]'>
        {!isLoading ? (
          <>
            {isImage ? (
              <img
                className='rounded-[20px] w-full h-40 sm:h-60 lg:h-80'
                width={600}
                src={news?.file}
              />
            ) : null}
            {isVideo ? (
              <video
                className={
                  'rounded-[20px] w-full h-40 sm:h-60 lg:h-80 hover:opacity-50 cursor-pointer'
                }
                src={news?.file}
                controls
              />
            ) : null}
            <div className='flex flex-col'>
              <Typography className='text-[20px] font-semibold' textGradient>
                {news?.title}
              </Typography>
              <Typography className='text-base font-normal text-borderWhite mt-[14px]'>
                {news?.createdAt
                  ? dayjs(news?.createdAt).format('YYYY-MMM-DD')
                  : 'N/A'}
              </Typography>
              <Typography className='text-base font-normal mt-[16px]'>
                {news?.description ?? strings.addNews.noDescriptionFound}
              </Typography>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default withProtectedRoute(NewsDetail);
