import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import BannerAlert from '@/components/BannerAlert';
import Loader from '@/components/Loader';
import { COMMON_ERROR } from '@/constants/feedback';
import { signInAsUser } from '@/services/auth';

import EmailForm from './EmailForm';
export type IEmailField = {
  email: string;
};

const SignInAsUser = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [showEmailSection, setShowEmailSection] = useState(true);
  const [password, setPassword] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IEmailField>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (showEmailSection) {
      setPassword('');
      setEmailAddress('');
    }
  }, [showEmailSection]);

  const handleEmailSubmit: SubmitHandler<IEmailField> = async (
    data: IEmailField,
  ) => {
    try {
      setIsLoading(true);

      const response = await signInAsUser(data);
      if (!response) return;

      setEmailAddress(response?.data?.email);
      setPassword(response?.data?.password);
      setShowEmailSection(false);
    } catch (error) {
      toast.error(COMMON_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='h-[85vh] mt-6 p-3 border border-borderWhite rounded-xl '>
      {showEmailSection ? (
        <EmailForm
          isLoading={isLoading}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          handleEmailSubmit={handleEmailSubmit}
        />
      ) : (
        <div className='h-full flex flex-col'>
          {!iframeLoaded ? (
            <div className='flex items-center justify-center flex-grow'>
              <Loader />
            </div>
          ) : null}
          <>
            {iframeLoaded ? (
              <div className='flex-none xl:h-[3rem]'>
                <BannerAlert
                  text={`Please logout if not already and use these credentials to login. Email: ${emailAddress} Password: ${password}`}
                  alertType='success'
                />
              </div>
            ) : null}
            <div id='iframeContainer' className={'w-full flex-grow flex'}>
              <iframe
                id={'myIframe'}
                className='w-full h-full mt-2'
                src={process.env.REACT_APP_FRONTEND_URL}
                onLoad={() => setIframeLoaded(true)}
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default SignInAsUser;
