import newsImage from '@/assets/svgs/newsImage.svg';

import { NEWS_PUBLISH_STATUS } from './common';

export const NEWS_SECTION = {
  title: 'News and Videos',
  noData: 'No data available',
  news: [
    {
      id: 1,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
      status: NEWS_PUBLISH_STATUS.POSTED,
    },
    {
      id: 2,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',

      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
        status: NEWS_PUBLISH_STATUS.POSTED,
      },
    },
    {
      id: 3,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
      status: NEWS_PUBLISH_STATUS.POSTED,
    },
    {
      id: 4,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
      status: NEWS_PUBLISH_STATUS.POSTED,
    },
    {
      id: 5,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 6,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 7,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 8,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',

      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 9,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 10,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 11,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
    {
      id: 12,
      title: 'FDX Break Out For FREE Adjusting to NO RISK Trade',
      date: 'March 18, 2024',
      description:
        'Lately, some trader friends of mine have been asking me about short-dated options and whether they should trade them or no...',
      image: {
        name: 'Screenshot 2024-05-22 at 12.52.00 PM.png',
        type: 'image',
        url: newsImage,
      },
    },
  ],
};

export const NEWS_DETAIL = {
  title: 'News and Videos Details',
  buttonText: 'Post News',
  unpostedButtonText: 'Revoke News Post',
};
