import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { IUserDetail, userDataType } from '@/@types';
import { COMMON_ERROR } from '@/constants/feedback';
import useAuthContext from '@/hooks/useAuth';
import { getAllUsers } from '@/services/users';

import { DashboardContext } from './context';

type PROPS = {
  children:
    | null
    | boolean
    | undefined
    | React.ReactChild
    | React.ReactPortal
    | React.ReactFragment;
};

const DashboardProvider: React.FC<PROPS> = ({ children }) => {
  const [dataList, setDataList] = useState<userDataType[]>([]);
  const [user, setUser] = useState<IUserDetail | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const { auth } = useAuthContext();

  const fetchAllUsers = async () => {
    try {
      const response = await getAllUsers(currentPage);
      setDataList((response?.data?.users as userDataType[]) ?? []);
      setTotalPages(response?.data?.totalPages);
      setIsLoading(false);
    } catch (error) {
      toast.error(COMMON_ERROR);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetchAllUsers();
  }, [currentPage, auth]);

  const contextValue = {
    user,
    setUser,
    dataList,
    setDataList,
    currentPage,
    setCurrentPage,
    totalPages,
    isLoading,
    setIsLoading,
  };
  return (
    <DashboardContext.Provider value={contextValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;

export { DashboardContext };
