import * as React from 'react';

import { svgTypes } from '@/@types';

const ReviewIcoon: React.FC<svgTypes> = ({
  colorOne = '#1B9FDF',
  colorTwo = '#AC7AFD',
}) => (
  <svg
    width='25'
    height='21'
    viewBox='0 0 25 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <linearGradient
        id='gradient9'
        x1='0'
        y1='0'
        x2='24'
        y2='22'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={colorOne} />
        <stop offset='1' stopColor={colorTwo} />
      </linearGradient>
    </defs>
    <path
      d='M7.90556 13.5292H17.0944V13.3114C17.0944 12.5458 16.687 11.9534 15.8722 11.5343C15.0574 11.1151 13.9333 10.9051 12.5 10.9042C11.0667 10.9034 9.94213 11.1134 9.12639 11.5343C8.31065 11.9551 7.9037 12.5475 7.90556 13.3114V13.5292ZM12.5 8.78325C13.0861 8.78325 13.5796 8.59381 13.9806 8.21494C14.3815 7.83606 14.5824 7.36925 14.5833 6.8145C14.5843 6.25975 14.3833 5.79338 13.9806 5.41538C13.5778 5.03738 13.0843 4.8475 12.5 4.84575C11.9157 4.844 11.4222 5.03388 11.0194 5.41538C10.6167 5.79688 10.4157 6.26325 10.4167 6.8145C10.4176 7.36575 10.6185 7.83256 11.0194 8.21494C11.4204 8.59731 11.9139 8.78675 12.5 8.78325ZM8.33333 21V18.375H2.24306C1.60417 18.375 1.07083 18.1729 0.643056 17.7686C0.215278 17.3644 0.000925926 16.8599 0 16.2553V2.11969C0 1.51594 0.214352 1.01194 0.643056 0.607687C1.07176 0.203437 1.60509 0.000875 2.24306 0H22.7569C23.3958 0 23.9292 0.202562 24.3569 0.607687C24.7847 1.01281 24.9991 1.51681 25 2.11969V16.2553C25 16.8591 24.7861 17.3631 24.3583 17.7673C23.9306 18.1716 23.3968 18.3741 22.7569 18.375H16.6667V21H8.33333ZM2.24306 17.0625H22.7569C22.9699 17.0625 23.1657 16.9785 23.3444 16.8105C23.5231 16.6425 23.612 16.4574 23.6111 16.2553V2.11969C23.6111 1.91844 23.5222 1.73337 23.3444 1.5645C23.1667 1.39562 22.9708 1.31163 22.7569 1.3125H2.24306C2.03009 1.3125 1.83426 1.3965 1.65556 1.5645C1.47685 1.7325 1.38796 1.91756 1.38889 2.11969V16.2553C1.38889 16.4566 1.47778 16.6416 1.65556 16.8105C1.83333 16.9794 2.02917 17.0634 2.24306 17.0625Z'
      fill='url(#gradient9)'
    />
  </svg>
);
export default ReviewIcoon;
