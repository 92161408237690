import React from 'react';

import { IUserDetail } from '@/@types';
import { CARD_TYPE } from '@/constants/common';
import { NEWS_SECTION } from '@/constants/newsAndVideos';
import { strings } from '@/locales';

import UserImg from 'src/assets/images/Avatar.png';
import cardImage from 'src/assets/svgs/card.svg';
import visaCardImage from 'src/assets/svgs/visa-card.svg';
import { Typography } from 'src/components/Typography';

type LinkedCardProps = {
  user: IUserDetail | undefined;
};

const LinkedCard: React.FC<LinkedCardProps> = ({ user }) => {
  const { user: userInfo, cardDetails } = user ?? {};
  const { cardType, last4, expMonth, expYear } = cardDetails ?? {};
  const { image, firstName, lastName } = userInfo ?? {};
  return (
    <div className='mt-6 w-full border border-borderWhite rounded-xl p-5'>
      <div className='flex justify-between'>
        <Typography variant='subheading' className='text-lg md:text-2xl'>
          {strings.userDetailScreen.linkedCard}
        </Typography>
      </div>
      <div className='lg:w-2/3 md:grid grid-cols-2 mt-5 lg:mt-0 items-center space-y-5 md:space-y-0 gap-10 text-white'>
        <Typography className='text-sm'>
          {strings.userDetailScreen.linkedCardText}
        </Typography>
        <div className='border border-borderColor px-5 pt-3 pb-1 rounded-2xl bg-bobleBlack'>
          {cardDetails ? (
            <>
              <div className='flex items-center gap-1 my-2'>
                <img src={image || UserImg} className='size-8 rounded-full' />
                <Typography className='text-base'>
                  {firstName && lastName
                    ? `${firstName} ${lastName}`
                    : 'John Doe'}
                </Typography>
              </div>
              <div className='flex gap-1 items-start '>
                {cardType === CARD_TYPE.VISA ? (
                  <img alt='visa' src={visaCardImage} className='sm:w-14' />
                ) : null}
                {cardType === CARD_TYPE.MASTER ? (
                  <img alt='card' src={cardImage} className='min-w-14' />
                ) : null}
                <div>
                  <Typography className='text-xs'>
                    {`*******${last4 || '1234'} | Expires ${expMonth || '09'}/${
                      expYear || '2024'
                    }`}
                  </Typography>
                  <Typography className='text-xs text-gray-400'>
                    {strings.userDetailScreen.defaultPaymentMethod}
                  </Typography>
                </div>
              </div>
            </>
          ) : (
            <Typography className='flex items-center justify-center h-20 text-base text-borderWhite'>
              {NEWS_SECTION.noData}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkedCard;
