import * as React from 'react';

import { svgTypes } from '@/@types';

const ReviewIcoon: React.FC<svgTypes> = ({
  colorOne = '#1B9FDF',
  colorTwo = '#AC7AFD',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='20'
    fill='none'
    viewBox='0 0 22 20'
  >
    <defs>
      <linearGradient
        id='gradient1'
        x1='0'
        y1='0'
        x2='24'
        y2='22'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={colorOne} />
        <stop offset='1' stopColor={colorTwo} />
      </linearGradient>
    </defs>
    <path
      fill='url(#gradient1)'
      d='M5.124 13.312h1.305a.95.95 0 00.367-.07.986.986 0 00.33-.235l6.145-6.304c.105-.123.184-.253.236-.388.052-.136.078-.27.08-.404a.987.987 0 00-.084-.398 1.595 1.595 0 00-.232-.382L12.146 3.94a1.007 1.007 0 00-.363-.242 1.042 1.042 0 00-.803 0 1.322 1.322 0 00-.38.242l-6.167 6.305c-.106.109-.182.222-.227.338a1.033 1.033 0 00-.069.379V12.3c0 .29.094.532.281.724a.943.943 0 00.706.288zm.094-1.111v-1.192l4.214-4.323.565.612.573.603-4.191 4.3H5.218zm4.779-4.903l.573.603-1.137-1.216.564.613zm.77 6.012h6.486a.612.612 0 00.611-.627.613.613 0 00-.176-.447.584.584 0 00-.436-.18H11.99l-1.223 1.254zm-7.005 4.245l-2.083 2.137c-.308.316-.665.39-1.07.218-.405-.172-.608-.483-.609-.934V2.026C0 1.45.189.966.566.581.943.194 1.413 0 1.976 0h18.05c.562 0 1.032.194 1.41.58.377.388.565.87.564 1.446V15.53c0 .577-.188 1.059-.565 1.446-.376.387-.846.58-1.409.579H3.762zM3.24 16.3h16.787c.188 0 .36-.08.517-.24a.74.74 0 00.235-.531V2.025a.748.748 0 00-.235-.53c-.156-.162-.329-.242-.518-.241H1.974c-.188 0-.36.08-.517.24a.74.74 0 00-.235.531v16.339L3.24 16.3z'
    ></path>
  </svg>
);
export default ReviewIcoon;
