export const LOGIN = {
  TITLE: 'Welcome Back!',
  HEADING: 'Today is a new day. Please enter your details.',
  SUB_HEADING: 'Boiling Options',
  PARAGRAPH:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.",
  EMAIL: { title: 'Email', placeholder: 'Enter email address' },
  PASSWORD: { title: 'Password', placeholder: 'Enter password' },
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD: 'Forgot password?',
  LOG_IN: 'Log In',
  FOOTER_TEXT: '© 2024 Galileo Alpha Sigma LLC | Privacy | Terms',
};
export const VERIFICATION = {
  TITLE: 'OTP Verification',
  ENTER_OTP: 'Enter the OTP sent to ',
  VERIFY: 'Verify',
  RESEND_OTP: 'Resend OTP',
  BACK_TO: 'Back to',
  LOGIN: 'Log In',
};
export const FORGOT_PASSWORD = {
  TITLE: 'Forgot Password',
  DESC: "Enter your account's email address and we will send you a link to reset your password.",
  EMAIL: { title: 'Email', placeholder: 'Enter email address' },
  SUBMIT: 'Submit',
  BACK_TO: 'Back to',
  LOGIN: 'Log In',
};
export const SET_NEW_PASSWORD = {
  TITLE: 'Set New Password',
  NEW_TO_BO: 'New to boiling options?',
  PASSWORD: { title: 'Password', placeholder: 'Enter password' },
  CONFIRM_REMEMBER_ME: {
    title: 'Confirm Password',
    placeholder: 'Enter Confirm password',
  },
  SIGNUP: 'Sign up',
  SUBMIT: 'Submit',
};

export enum OTP_TYPE {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
}
export const IFRAME_EMAIL_SCREEN = {
  TITLE: 'Email',
  DESC: 'Enter the email of the user you want to log in as.',
  EMAIL: { title: 'Email', placeholder: 'Enter email address' },
  SUBMIT: 'Submit',
};
