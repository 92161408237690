import { GetCity, GetState } from 'react-country-state-city';
import { SubmitHandler } from 'react-hook-form';

import { IUserDetail, userDataType } from '@/@types';
import { loggedInUserType, UserType } from '@/@types/authTypes';
import { updateUser } from '@/services/users';

import useAuthContext from './useAuth';
import useDashboardContext from './useDashboard';
import { useGetLocation } from './useLocation';

export const useUserDetail = (
  isEdit: boolean,
  setIsEdit: (value: boolean) => void,
) => {
  const { user, setUser, setDataList } = useDashboardContext();
  const { auth, setAuth } = useAuthContext();
  const { location } = useGetLocation();

  const updateProfileHandler: SubmitHandler<userDataType> = async (data) => {
    const country = location.countryList[parseInt(String(data.country))];
    const states = await GetState(country?.id);
    const state = states[parseInt(String(data.state))];
    const cities = await GetCity(country?.id, state?.id);
    const city = cities[parseInt(String(data.city))];
    const payload = {
      ...data,
      country: country?.name || '',
      state: state?.name || '',
      city: city?.name || '',
    };
    const response = await updateUser(user?.user?._id as string, payload);
    if (response?.status) {
      if (response?.data?.data._id === auth?.user._id) {
        setAuth((prevAuth: loggedInUserType | null) => {
          if (!prevAuth) {
            return null;
          }
          return {
            ...prevAuth,
            user: response?.data?.data
              ? (response?.data?.data as unknown as UserType)
              : prevAuth.user,
          };
        });
      }
      setIsEdit(false);
      setUser((prevUser: IUserDetail | undefined) => {
        if (!prevUser) {
          return undefined;
        }
        return {
          ...prevUser,
          user: response?.data?.data
            ? (response?.data?.data as unknown as userDataType)
            : prevUser.user,
        };
      });
      setDataList((prevDataList: userDataType[]) =>
        prevDataList.map((item: userDataType) => {
          if (item._id === user?.user?._id && response?.data?.data) {
            return {
              ...(item as object),
              ...(response.data.data as object),
            } as userDataType;
          }
          return item;
        }),
      );
    }
    setIsEdit(!isEdit);
  };

  return { updateProfileHandler };
};
