import { flexRender, Row } from '@tanstack/react-table';

import { cn } from 'src/lib/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PinnedRow = ({ row }: { row: Row<any> }) => {
  return (
    <tr
      className={cn(
        'text-white rounded-2xl border-transparentGreen bg-transparentGreen/10 border-2',
        'sticky',
        {
          [`top-[${
            row.getIsPinned() === 'top'
              ? `${row.getPinnedIndex() * 26 + 48}px`
              : '0px'
          }]`]: row.getIsPinned() === 'top',
        },
        'animate-rowPinAnimate',
      )}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <td className='text-center py-2 my-2' key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

export default PinnedRow;
