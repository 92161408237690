import toast from 'react-hot-toast';

import { ResponseType } from '@/@types/authTypes';
import {
  IHourList,
  IHourPayload,
  IHoursListResponse,
} from '@/@types/finvizTypes';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR, COMMON_SUCCESS } from '@/constants/feedback';
import { REST_SUB_URL } from '@/constants/url';

export const getAllReviews = async (pageNumber: number) => {
  const response = await API.Get<ResponseType<IHoursListResponse>>(
    `${REST_SUB_URL.GET_ALL_REVIEWS}/${pageNumber}`,
  );
  return response?.data;
};
export const getHoursList = async () => {
  const response = await API.Get<ResponseType<IHourList[]>>(
    `${REST_SUB_URL.GET_HOURS_LIST}`,
  );
  return response?.data;
};

export const postHoursList = async (payload: IHourList[]) => {
  const updatedPayload: IHourPayload = {
    data: payload.map((item: IHourList) => ({
      hour_: item.hour_,
      id: item.id,
    })),
  };
  const response = await API.Post<IHourPayload, IHoursListResponse>(
    REST_SUB_URL.POST_HOUR_LIST,
    updatedPayload,
  );
  if (response.status) {
    toast.success(response?.data?.message || COMMON_SUCCESS);
    return response;
  }
  toast.error(response?.message || COMMON_ERROR);
  return response;
};
