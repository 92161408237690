import React from 'react'; // Import ReactNode type

import { loggedInUserType } from '@/@types/authTypes';

export const AuthContext = React.createContext<{
  auth: loggedInUserType | null;
  setAuth: React.Dispatch<React.SetStateAction<loggedInUserType | null>>;
}>({
  auth: null,
  setAuth: () => {},
});
