import React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md';

import { userDataType } from '@/@types';
import { ReviewType } from '@/@types/reviewsType';
import { Table } from '@tanstack/react-table';

import { cn } from 'src/lib/utils';

import { IStockRecord } from './makeData';

const PaginationButton = ({
  canChangePage,
  changePage,
  children,
  isActive,
}: {
  canChangePage: boolean;
  changePage: () => void;
  children: React.ReactNode;
  isActive?: boolean;
}) => (
  <button
    className={cn(
      'rounded-md flex justify-center bg-white text-slate-800 items-center p-1 size-8',
      {
        'text-slate-500': !canChangePage,
        'hover:bg-gray-200 hover:cursor-pointer': canChangePage,
        'bg-homeWorld text-white hover:bg-homeWorld/90': isActive,
      },
    )}
    onClick={changePage}
    disabled={!canChangePage}
  >
    {children}
  </button>
);

const Pagination = ({
  table,
  setCurrentPage,
  currentPage,
  totalPages,
}: {
  table: Table<IStockRecord | userDataType | ReviewType>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>> | undefined;
  currentPage: number;
  totalPages: number | undefined;
}) => {
  const totalPagesNum = totalPages || table.getPageCount();

  const canNavigatePrevious = currentPage > 1;
  const canNavigateNext = currentPage < totalPagesNum;

  const goToFirstPage = () => {
    table.setPageIndex(0);
    if (setCurrentPage) setCurrentPage(1);
  };

  const goToPreviousPage = () => {
    table.previousPage();
    if (setCurrentPage) setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToPage = (pageNumber: number) => {
    table.setPageIndex(pageNumber - 1);
    if (setCurrentPage) setCurrentPage(pageNumber);
  };

  const goToNextPage = () => {
    table.nextPage();
    if (setCurrentPage)
      setCurrentPage((prev) => Math.min(prev + 1, totalPagesNum));
  };

  const goToLastPage = () => {
    table.setPageIndex(totalPagesNum - 1);
    if (setCurrentPage) setCurrentPage(totalPagesNum);
  };

  const renderPageNumbers = () => {
    if (totalPagesNum === 1)
      return (
        <PaginationButton
          canChangePage={false}
          changePage={() => {}}
          isActive={true}
        >
          {currentPage}
        </PaginationButton>
      );
    const pages = [];
    for (let i = 1; i <= totalPagesNum; i++) {
      pages.push(
        <PaginationButton
          key={i}
          canChangePage={true}
          changePage={() => goToPage(i)}
          isActive={i === currentPage}
        >
          {i}
        </PaginationButton>,
      );
    }
    return pages;
  };

  return (
    <div className='px-4 flex justify-end gap-2'>
      <span></span>
      <PaginationButton
        canChangePage={canNavigatePrevious}
        changePage={goToFirstPage}
      >
        <MdKeyboardDoubleArrowLeft size={20} />
      </PaginationButton>
      <PaginationButton
        canChangePage={canNavigatePrevious}
        changePage={goToPreviousPage}
      >
        <MdKeyboardArrowLeft size={20} />
      </PaginationButton>
      {renderPageNumbers()}
      <PaginationButton
        canChangePage={canNavigateNext}
        changePage={goToNextPage}
      >
        <MdKeyboardArrowRight size={20} />
      </PaginationButton>
      <PaginationButton
        canChangePage={canNavigateNext}
        changePage={goToLastPage}
      >
        <MdKeyboardDoubleArrowRight size={20} />
      </PaginationButton>
    </div>
  );
};

export default Pagination;
