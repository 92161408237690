export const COMMON_ERROR = 'Something went wrong!';
export const COMMON_SUCCESS = 'Operation completed successfully!';

export const REVIEW = {
  TITLE: 'Write a Review',
  BUTTON_TEXT: 'Send your review',
  SUB_HEADING: 'Rating',
  CLICK_TO_RATE: 'Click To Rate',
  YOUR_REVIEW: 'Your Review',
  PLACEHOLDER: 'Enter your review',
};
export const INPUT_ERRORS = {
  EMAIL: {
    required: '*Email is required.',
    pattern: '*Enter a valid email.',
  },
  PASSWORD: {
    required: '*Password is required.',
  },
  CONFIRM_PASSWORD: {
    required: '*Confirm Password is required.',
    match: '*Passwords do not match',
  },
  REVIEW: {
    required: '*Review is required.',
  },
  COUNTRY: {
    required: '*Country is required.',
  },
  ZIP_CODE: {
    required: '*Zip code is required.',
  },
  FIRST_NAME: {
    required: '*First name is required.',
  },
  LAST_NAME: {
    required: '*Last name is required.',
  },
  PHONE: {
    required: '*Phone number is required.',
  },
  ROLE: {
    required: '*Role is required.',
  },
  STATUS: {
    required: '*Status is required.',
  },
  TITLE: {
    required: '*Title is required',
  },
  AGREEMENT: {
    required: '*Agreement is required',
  },
  TEXT: {
    required: '*Text is required',
  },
  FILE: {
    required: '*File is required',
  },
};

export enum TOAST_MESSAGE_ENUM {
  SUCCESS = 'success',
  DESTRUCTIVE = 'destructive',
  DEFAULT = 'default',
}

export enum TOAST_TITLE {
  SUCCESS = 'Success',
  ERROR = 'Error',
}
