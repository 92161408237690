import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { Row } from '@tanstack/react-table';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

interface MinimalColumn {
  id: string;
}

interface Original {
  [key: string]: unknown;
}

export const calculateWidth = (
  header: MinimalColumn,
  table: { getRowModel: () => { rows: Array<Row<Original>> } },
) => {
  if (
    header.id !== 'check' &&
    table.getRowModel().rows[0]?.original?.[header.id] &&
    Number(table.getRowModel().rows[0]?.original?.[header.id]) < 70
  ) {
    return `${
      String(table.getRowModel().rows[0]?.original?.[header.id]).length * 8 + 10
    }px`;
  }
  return '';
};
