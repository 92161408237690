import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { ReviewType } from '@/@types/reviewsType';
import PostIcon from '@/assets/svgs/postIcon';
import FilterTable from '@/components/FilterTable';
import { getReviewsColumns } from '@/components/FilterTable/Columns';
import Loader from '@/components/Loader';
import Modal from '@/components/Modal';
import withProtectedRoute from '@/components/ProtectedRoute';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import useReviewHook from '@/hooks/useReviewHook';
import { strings } from '@/locales';
import { getAllReviews } from '@/services/reviews';

const Reviews = () => {
  const [showFullReview, setShowFullReview] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number | undefined>(1);
  const [allReviews, setAllReviews] = useState<ReviewType[] | undefined>([]);
  const [postedReviews, setPostedReviews] = useState<ReviewType[] | undefined>(
    [],
  );
  const [rowSelectionData, setRowSelectionData] = useState<ReviewType[]>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('');
  // ser Text of button:
  useEffect(() => {
    if (rowSelectionData?.length === 0 && (postedReviews ?? []).length !== 0) {
      setButtonText(strings.review.revokeReview);
      setIsButtonDisabled(false);
    } else if (
      rowSelectionData?.length !== 0 &&
      (postedReviews ?? []).length === 0
    ) {
      setButtonText(strings.review.buttonText);
      setIsButtonDisabled(false);
    } else if (
      rowSelectionData?.length !== 0 &&
      (postedReviews ?? []).length !== 0
    ) {
      const rowSelectionIds = new Set(
        rowSelectionData?.map((item) => item._id),
      );
      const postedReviewIds = new Set(
        postedReviews?.map((item: ReviewType) => item._id),
      );
      const isUpdated =
        [...rowSelectionIds].some((id) => !postedReviewIds.has(id)) ||
        [...postedReviewIds].some((id) => !rowSelectionIds.has(id));
      if (isUpdated) {
        setButtonText(strings.review.updateReview);
        setIsButtonDisabled(false);
      } else {
        setButtonText(strings.review.noOperation);
        setIsButtonDisabled(true);
      }
    } else {
      setButtonText(strings.review.noOperation);
      setIsButtonDisabled(true);
    }
  }, [rowSelectionData]);
  // toggle Review column
  const handleShowFullReview = (row: {
    row: { original: { _id: number } };
  }) => {
    setShowFullReview(
      showFullReview === row.row.original._id ? null : row.row.original._id,
    );
  };
  // create Review columns
  const columns = useMemo(
    () => getReviewsColumns(showFullReview, handleShowFullReview),
    [showFullReview, handleShowFullReview],
  );

  // fetch all reviews function
  const fetchAllReviews = async () => {
    try {
      const response = await getAllReviews(currentPage);
      setAllReviews(response?.data?.reviewsWithUser);
      setTotalPages(response?.data?.totalPages);
      setIsLoading(false);
    } catch (error) {
      toast.error(strings.review.errorMessage);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetchAllReviews();
  }, [currentPage]);

  useEffect(() => {
    fetchPostedReviews();
  }, []);

  const { handlePostReview, fetchPostedReviews } = useReviewHook({
    postedReviews,
    setRowSelectionData,
    rowSelectionData,
    setAllReviews,
    setShowConfirmationModal,
    setPostedReviews,
  });

  return (
    <div className='my-6 ml-[16px] mr-6'>
      <div className='flex justify-between mx-1 items-center'>
        <Typography variant='p' className='font-semibold text-xl'>
          {strings.review.title}
        </Typography>
        <div className='flex gap-3'>
          <Button
            variant={'primary'}
            disabled={isButtonDisabled}
            onClick={() => setShowConfirmationModal(true)}
            className='rounded-full p-3 sm:p-5 font-medium text-base'
          >
            <PostIcon className='mr-2 sm:mr-4' />
            {buttonText}
          </Button>
        </div>
      </div>
      <div className='rounded-[14px] mt-[33px]'>
        {isLoading ? (
          <div className='flex items-center justify-center h-40'>
            <Loader />
          </div>
        ) : (
          <FilterTable
            columns={columns}
            data={allReviews}
            applyCenterClass
            setRowSelectionData={setRowSelectionData}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        )}
        <Modal
          title={strings.review.modalTitle}
          btnText='Post'
          handleBtn={handlePostReview}
          open={showConfirmationModal}
          setOpen={setShowConfirmationModal}
        >
          <div>
            <Typography variant='p' className='text-base mt-3 mb-5'>
              {strings.review.confirmaton}
            </Typography>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default withProtectedRoute(Reviews);
