import { cn } from 'src/lib/utils';

interface ITypography {
  children: React.ReactNode;
  className?: string;
  variant?: 'title' | 'heading' | 'subheading' | 'xl' | 'lg' | 'p';
  noBold?: boolean;
  block?: boolean;
  textGradient?: boolean;
}

export const Typography = ({
  variant = 'p',
  className = '',
  noBold = false,
  block = false,
  textGradient = false,
  children,
}: ITypography) => (
  <span
    className={cn(
      'text-white',
      {
        block,
        'mx-1 inline-block': !block,
        'font-semibold': !noBold,
        'text-4xl !leading-tight md:text-5xl xl:text-6xl': variant === 'title',
        'text-3xl font-bold leading-[44px] md:text-[40px]':
          variant === 'heading',
        'text-3xl': variant === 'subheading',
        'text-lg font-normal leading-[27px]': variant === 'p',
        'inline-block bg-gradient-to-r from-babyBlue to-lavender bg-clip-text text-transparent':
          textGradient,
      },
      className,
    )}
  >
    {children}
  </span>
);
