import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import FormField from '@/components/FormField';
import withUnauthenticatedRoute from '@/components/PublicRoutes';
import { Checkbox } from '@/components/ui/checkbox';
import useAuthContext from '@/hooks/useAuth';
import { ROUTES } from '@/routes';
import { login } from '@/services/auth';
import { getPersonalInfo } from '@/services/profile';
import { ILoginFields } from '@/services/types';

import Loader from 'src/components/Loader';
import { Typography } from 'src/components/Typography';
import { Button } from 'src/components/ui/button';
import { LOGIN } from 'src/constants/auth';
import { INPUT_ERRORS } from 'src/constants/feedback';
import { EmailRegex, getAuthToken } from 'src/utils/common';

import AuthWrapper from '../components/AuthWrapper';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isRemember, setIsRemember] = useState(false);
  const { setAuth } = useAuthContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFields>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const getProfile = async () => {
    const response = await getPersonalInfo();
    return response;
  };
  const setUser = async () => {
    const token = getAuthToken();
    if (token) {
      const res = await getProfile();
      if (res) {
        setAuth({ token: String(token), user: res.data });
      }
    }
  };

  const handleLogin: SubmitHandler<ILoginFields> = async (formData) => {
    setIsLoading(true);
    const response = await login(formData, isRemember);
    setIsLoading(false);
    if (!response) return;
    await setUser();
    navigate(ROUTES.DASHBOARD);
  };

  const toggleRemember = () => {
    setIsRemember(!isRemember);
  };

  return (
    <AuthWrapper>
      <Typography
        variant='subheading'
        className='mx-auto my-4 text-center capitalize'
      >
        {LOGIN.TITLE}
      </Typography>
      <Typography variant='p' className='mx-auto text-center'>
        {LOGIN.HEADING}{' '}
      </Typography>
      <Controller
        control={control}
        name='email'
        rules={{
          required: INPUT_ERRORS.EMAIL.required,
          pattern: {
            value: EmailRegex,
            message: INPUT_ERRORS.EMAIL.pattern,
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={LOGIN.EMAIL.title}
            placeholder={LOGIN.EMAIL.placeholder}
            name={name}
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <Controller
        control={control}
        name='password'
        rules={{
          required: INPUT_ERRORS.PASSWORD.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={LOGIN.PASSWORD.title}
            placeholder={LOGIN.PASSWORD.placeholder}
            name={name}
            type='password'
            value={value}
            onChange={onChange}
            errors={errors}
          />
        )}
      />
      <div className='flex items-center justify-between'>
        <label className='flex items-center gap-2' onClick={toggleRemember}>
          <Checkbox className='bg-darkGray data-[state=checked]:bg-darkGray rounded-[4px]' />
          <Typography className='my-2 text-sm 2xl:text-lg'>
            {LOGIN.REMEMBER_ME}
          </Typography>
        </label>
        <Link to={ROUTES.FORGOT_PASSWORD}>
          <Typography className='text-skyBlue underline text-sm 2xl:text-lg'>
            {LOGIN.FORGOT_PASSWORD}
          </Typography>
        </Link>
      </div>
      <Button
        variant='gradient'
        className='my-4 text-lg'
        onClick={handleSubmit(handleLogin)}
      >
        {isLoading ? <Loader loading={isLoading} /> : LOGIN.LOG_IN}
      </Button>
    </AuthWrapper>
  );
};

export default withUnauthenticatedRoute(Login);
