import { Control, Controller, FieldErrors } from 'react-hook-form';

import { userDataType } from '@/@types';
import FormField from '@/components/FormField';
import { INPUT_ERRORS } from '@/constants/feedback';
import { LocationState } from '@/hooks/useLocation';
import { strings } from '@/locales';

import CitySelect from './(Components)/CitySelect';
import CountrySelect from './(Components)/CountrySelect';
import StateSelect from './(Components)/StateSelect';

const EditAddressInfo: React.FC<{
  errors: FieldErrors<userDataType>;
  control: Control<userDataType>;
  location: LocationState;
  setLocation: React.Dispatch<React.SetStateAction<LocationState>>;
}> = ({ control, errors, location, setLocation }) => {
  const staticText = strings.userDetailScreen;
  return (
    <div className='grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7 '>
      <Controller
        control={control}
        name='country'
        render={({ field: { onChange, value } }) => (
          <CountrySelect
            errors={errors}
            value={value}
            onChange={onChange}
            countryId={location.countryId}
            setCountryId={(newCountryId) =>
              setLocation((prevLocation: LocationState) => ({
                ...prevLocation,
                countryId: newCountryId,
                stateId: 0,
                cityId: 0,
                cityList: [],
              }))
            }
            countryList={location.countryList}
            setStateList={(newStateList) =>
              setLocation((prevLocation: LocationState) => ({
                ...prevLocation,
                stateList: newStateList,
              }))
            }
          />
        )}
      />
      <Controller
        control={control}
        name='state'
        render={({ field: { onChange, value } }) => (
          <StateSelect
            value={value as string}
            onChange={onChange}
            errors={errors}
            countryId={Number(location.countryId)}
            stateList={location.stateList}
            setCityList={(newCityList) => {
              setLocation((prevLocation: LocationState) => ({
                ...prevLocation,
                cityList: newCityList,
              }));
            }}
            setStateId={(newStateId) => {
              setLocation((prevLocation: LocationState) => ({
                ...prevLocation,
                stateId: newStateId,
              }));
            }}
            stateId={location.stateId}
          />
        )}
      />
      <Controller
        control={control}
        name='city'
        render={({ field: { onChange, value } }) => (
          <CitySelect
            cityList={location.stateId < 0 ? [] : location?.cityList}
            isDisabled={location.stateId < 0 || !location.cityList}
            onChange={onChange}
            value={value}
            errors={errors}
            cityId={location.cityId}
            setCityId={(newCityId) =>
              setLocation((prevLocation: LocationState) => ({
                ...prevLocation,
                cityId: newCityId,
              }))
            }
          />
        )}
      />
      <Controller
        control={control}
        name='zipCode'
        rules={{
          required: INPUT_ERRORS.ZIP_CODE.required,
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={staticText.zipCode}
            placeholder={staticText.zipCodePlaceholder}
            name={name}
            value={value || ''}
            onChange={onChange}
            labelCalssName='text-base'
            errors={errors}
          />
        )}
      />
    </div>
  );
};
export default EditAddressInfo;
