import React, { useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa6';
import { MdOutlinePlayCircle } from 'react-icons/md';
import { TbTrash } from 'react-icons/tb';

import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

interface INewsCard {
  id: string;
  title: string;
  date?: string;
  file: string;
  isPosted?: boolean;
  onClick: () => void;
  handleEditClick: (id: string, e: React.MouseEvent) => void;
  handleTrashClick: (id: string) => void;
}

const NewsCard: React.FC<INewsCard> = ({
  id,
  title,
  date,
  onClick,
  file,
  handleEditClick,
  handleTrashClick,
  isPosted,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const handleTrashIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowConfirmationModal(true);
  };
  const handleDeleteConfirmed = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleTrashClick(id);
    setShowConfirmationModal(false);
  };
  useEffect(() => {
    const fileExtension = file.split('.').pop() || '';
    if (fileExtension === 'mp4') {
      setIsVideo(true);
    } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
      setIsImage(true);
    }
  }, [file]);

  return (
    <div
      className='rounded-[14px] bg-primaryBackground border border-borderWhite flex flex-col p-3'
      onClick={showConfirmationModal ? undefined : onClick}
    >
      <div
        className='relative rounded-[20px]'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isImage ? (
          <img
            className={
              'rounded-[20px] w-full h-40 hover:opacity-50 cursor-pointer'
            }
            src={file}
            alt={title}
          />
        ) : null}
        {isVideo ? (
          <div className='relative'>
            <video
              className={
                'rounded-[20px] h-40 w-full hover:opacity-50 cursor-pointer'
              }
              src={file}
            />
            <MdOutlinePlayCircle
              color='#2E3E4D'
              size={30}
              className='absolute inset-0 m-auto'
            />
          </div>
        ) : null}
        {isHovered ? (
          <div className='absolute inset-0 rounded-[20px] pointer-events-auto bg-black bg-opacity-30 z-50'>
            <div className='flex mr-2 absolute gap-1 right-3 top-3'>
              <div className='bg-darkGray size-7 rounded-[7px] flex items-center justify-center'>
                <BiEdit size={20} onClick={(e) => handleEditClick(id, e)} />
              </div>
              <div className='bg-darkGray size-7 rounded-[7px] flex items-center justify-center'>
                <TbTrash size={20} onClick={handleTrashIconClick} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Typography
        textGradient
        className='font-semibold text-[20px] leading-7 mt-[14px] mb-[11px]'
      >
        {title}
      </Typography>
      <div className='flex justify-between'>
        <Typography className='text-base font-normal text-borderWhite'>
          {date}
        </Typography>
        <div
          className={cn(
            'bg-greenishWhite px-2 rounded-[8px] flex justify-center items-center',
            {
              'hidden ': !isPosted,
            },
          )}
        >
          <FaCheck className='mr-0.5' size={15} color='#008237' />
          <Typography className='text-sm font-normal text-charmedGreen'>
            {strings.addNews.posted}
          </Typography>
        </div>
      </div>
      <Modal
        title={strings.addNews.modalTitle}
        btnText={strings.addNews.delete}
        handleBtn={handleDeleteConfirmed}
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
      >
        <div>
          <Typography variant='p' className='text-base mt-3 mb-5'>
            {strings.addNews.confirmaton}
          </Typography>
        </div>
      </Modal>
    </div>
  );
};

export default NewsCard;
