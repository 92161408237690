import { truncateText } from '@/utils/common';

import UserImg from 'src/assets/images/Avatar.png';
import { HEADER_TEXT } from 'src/constants/common';

import { Typography } from './Typography';

type UserDataProps = {
  user:
    | {
        avatar: string;
        firstName: string;
        lastName: string;
      }
    | undefined;
  userRole?: string;
};

const UserData: React.FC<UserDataProps> = ({ user, userRole }) => {
  return (
    <div className='flex'>
      <img src={user?.avatar || UserImg} className='size-12 rounded-full ' />
      <div className='text-white text-left ml-2 flex flex-col'>
        <Typography className='text-base font-medium mx-0 block capitalize'>
          {user?.firstName
            ? truncateText(`${user?.firstName} ${user?.lastName}`)
            : HEADER_TEXT.USER}
        </Typography>
        <Typography className='text-sm mx-0 flex items-center gap-1'>
          {userRole}
        </Typography>
      </div>
    </div>
  );
};

export default UserData;
