import { useEffect, useState } from 'react';
import { GetCity, GetCountries, GetState } from 'react-country-state-city';

export type CountryType = {
  id: number;
  sortname: string;
  name: string;
  phonecode: number;
};
export type StatesType = {
  id: number;
  name: string;
  country_id: number;
};
export type CityType = {
  id: number;
  name: string;
  state_id: number;
};

export type LocationState = {
  countryId: number;
  stateId: number;
  cityId: number;
  countryList: CountryType[];
  stateList: StatesType[];
  cityList: CityType[];
};
export const useGetLocation = () => {
  const [location, setLocation] = useState<LocationState>({
    countryId: 0,
    stateId: 0,
    cityId: 0,
    countryList: [],
    stateList: [],
    cityList: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const countries = await GetCountries();
      setLocation((prevLocation) => ({
        ...prevLocation,
        countryList: countries,
      }));
      const [firstCountry] = countries;
      if (firstCountry) {
        setLocation((prevLocation) => ({
          ...prevLocation,
          countryId: firstCountry.id,
        }));
        const states = await GetState(firstCountry.id);
        if (states && states.length > 0) {
          setLocation((prevLocation) => ({
            ...prevLocation,
            stateList: states,
            stateId: states[0]?.id,
          }));
          const cities = await GetCity(firstCountry.id, states[0]?.id);
          if (cities?.length > 0) {
            setLocation((prevLocation) => ({
              ...prevLocation,
              cityList: cities,
              cityId: cities[0]?.id,
            }));
          }
        }
      }
    };
    fetchData();
  }, []);

  return { location, setLocation };
};
