type CheckboxInputProps = {
  label: string;
  checked?: boolean;
  setChecked?: (event: unknown) => void;
};
const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  checked,
  setChecked,
}) => {
  return (
    <div className='flex items-center'>
      <input
        id='checkbox-all-search'
        type='checkbox'
        className='min-w-4 min-h-4 text-gradient-bg border-gray-300 rounded dark:border-gray-600'
        checked={checked}
        onChange={setChecked}
      />
      <label htmlFor='checkbox-all-search' className='sr-only'>
        {label}
      </label>
    </div>
  );
};

export default CheckboxInput;
