import { IoArrowBack } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { Typography } from '@/components/Typography';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';

const UserDetailTitleSection: React.FC = () => {
  const staticText = strings.userDetailScreen;

  return (
    <div className='flex'>
      <Link to={ROUTES.DASHBOARD}>
        <div className='size-8 bg-blueFantastic rounded-[6px] flex justify-center items-center mr-2 cursor-pointer'>
          <IoArrowBack size={20} />
        </div>
      </Link>
      <Typography variant='p' className='font-semibold text-xl mx-1'>
        {staticText.heading}
      </Typography>
    </div>
  );
};

export default UserDetailTitleSection;
