/* eslint-disable max-lines */

import { TbLogout2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import useAuthContext from '@/hooks/useAuth';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { removeAuthToken } from '@/utils/common';

const SidebarLogoutSection = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuthContext();

  const logoutUser = () => {
    removeAuthToken();
    setAuth(null);
    navigate(ROUTES.LOGIN);
  };

  return (
    <div className='rounded-full w-full bg-transparentWhite '>
      <div
        className='flex items-center px-4 py-3 text-red-500 group cursor-pointer'
        onClick={logoutUser}
      >
        <TbLogout2 size={20} />
        <span className='ml-4'>{strings.sidebar.logout}</span>
      </div>
    </div>
  );
};

export default SidebarLogoutSection;
