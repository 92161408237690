import React, { Suspense, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import stars from '@/assets/svgs/stars.svg';
import Header from '@/components/Header';
import Loader from '@/components/Loader';
import Sidebar from '@/components/Sidebar';
import useAuthContext from '@/hooks/useAuth';
import { cn } from '@/lib/utils';
import Dashboard from '@/pages/Dashboard';
import Stock from '@/pages/Stocks';
import UserDetail from '@/pages/UserDetail';
import DashboardProvider from '@/providers/dashboard';
import { protectedRoutes, ROUTES } from '@/routes';
import { getPersonalInfo } from '@/services/profile';
import { getAuthToken } from '@/utils/common';

import AddNewsOrVideos from '../AddNewsAndVideos';
import Agreement from '../Agreement';
import ForgotPassword from '../Auth/ForgotPassword';
import Login from '../Auth/Login';
import OTP from '../Auth/OTP';
import SetNewPassword from '../Auth/SetNewPassword';
import Finviz from '../Finviz';
import NewsDetail from '../NewsDetail';
import NewsList from '../NewsList';
import NotFound from '../NotFoundPage';
import Reviews from '../Reviews';
import SignInAsUser from '../SignInAsUser';

import 'src/assets/styles/App.css';

const App = () => {
  const [open, setOpen] = React.useState(false);
  const { auth, setAuth } = useAuthContext();

  const getProfile = async () => {
    const response = await getPersonalInfo();
    return response;
  };

  const setUser = async () => {
    const token = getAuthToken();
    if (token) {
      const res = await getProfile();
      if (res) {
        setAuth({ token: String(token), user: res.data });
      }
    }
  };

  useEffect(() => {
    setUser();
  }, []);
  const toggleSidebar = () => {
    setOpen(!open);
  };
  const location = useLocation();
  const isProtectedPage = protectedRoutes.some((route: string) =>
    location.pathname.includes(route),
  );
  return (
    <Suspense fallback={<Loader />}>
      <div className=' bg-primaryBackground '>
        {!isProtectedPage && !auth?.token ? (
          <>
            <img
              src={stars}
              className={cn('absolute left-0 z-0 bottom-5', {
                hidden: !auth?.token && isProtectedPage,
              })}
            />
            <div className='absolute bottom-0 opacity-30 -left-40 top-[-10%] h-[50vw] w-[50vw] rounded-full bg-deep-sea-gradient-radial' />
            <div className='absolute top-0 right-40 opacity-15 h-[50vw] w-[50vw] rounded-full bg-teal-green-gradient-radial' />
            <div className='absolute -bottom-20 left-20 opacity-15 h-[50vw] w-[50vw] rounded-full bg-teal-green-gradient-radial' />
          </>
        ) : null}
        {isProtectedPage ? (
          <Sidebar isOpen={open} toggleSidebar={toggleSidebar} />
        ) : null}
        <div
          className={cn('sm:ml-64 py-3 px-3 sm:px-5', {
            'p-0': !isProtectedPage,
            'm-0': !isProtectedPage,
            'sm:ml-0': !isProtectedPage,
          })}
        >
          {isProtectedPage ? (
            <Header isOpen={open} toggleSidebar={toggleSidebar} />
          ) : null}
          <div
            className={cn('text-white', {
              'bg-transparentWhite': !auth?.token,
            })}
          >
            <DashboardProvider>
              <Routes>
                <Route path={ROUTES.HOME}>
                  <Route
                    index
                    element={<Navigate to={ROUTES.LOGIN} replace />}
                  />
                  <Route path={ROUTES.LOGIN} element={<Login />} />
                  <Route path={ROUTES.OTP} element={<OTP />} />
                  <Route
                    path={ROUTES.FORGOT_PASSWORD}
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={ROUTES.SET_NEW_PASSWORD}
                    element={<SetNewPassword />}
                  />
                  <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
                  <Route path='/user-detail/:userId' element={<UserDetail />} />
                  <Route path={ROUTES.NEWS_LIST} element={<NewsList />} />
                  <Route
                    path={ROUTES.ADD_NEWS_AND_VIDEOS}
                    element={<AddNewsOrVideos />}
                  />
                  <Route path={ROUTES.REVIEWS} element={<Reviews />} />
                  <Route
                    path={ROUTES.SIGN_IN_AS_USER}
                    element={<SignInAsUser />}
                  />
                  <Route path={ROUTES.FINVIZ} element={<Finviz />} />
                  <Route path={ROUTES.AGREEMENT} element={<Agreement />} />
                  <Route
                    path={`${ROUTES.NEWS_DETAIL}/:newsId`}
                    element={<NewsDetail />}
                  />
                  <Route path={ROUTES.STOCK} element={<Stock />} />
                  <Route path='*' element={<NotFound />} />
                </Route>
              </Routes>
            </DashboardProvider>
          </div>
          <Toaster />
        </div>
      </div>
    </Suspense>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
