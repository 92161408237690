import * as React from 'react';

import { svgTypes } from '@/@types';

const UserIcon: React.FC<svgTypes> = ({
  colorOne = '#1B9FDF',
  colorTwo = '#AC7AFD',
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='20'
    fill='none'
    viewBox='0 0 22 20'
  >
    <defs>
      <linearGradient
        id='a'
        x1={1}
        x2={21}
        y1={1}
        y2={19}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor={colorOne} />
        <stop offset={1} stopColor={colorTwo} />
      </linearGradient>
    </defs>
    <path
      stroke='url(#a)'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M21 19v-2a4.002 4.002 0 00-3-3.874M14.5 1.291a4.001 4.001 0 010 7.418M16 19c0-1.864 0-2.796-.305-3.53a4 4 0 00-2.164-2.165C12.796 13 11.864 13 10 13H7c-1.864 0-2.796 0-3.53.305a4 4 0 00-2.166 2.164C1 16.204 1 17.136 1 19M12.5 5a4 4 0 11-8 0 4 4 0 018 0z'
    ></path>
  </svg>
);
export default UserIcon;
